import { ExtensionContext, ExtensionMode } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../ExtensionsApiExternalContract';

import { ExtensionEnvironment } from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings as EnumMappings, ApiVersion } from '../../ApiShared';

/**
 * Implementation of the external environment namespace.
 * Environment does not follow the Impl pattern as it is
 * just a property bag.
 */
export class Environment implements Contract.Environment {
  private _apiVersion: string;
  private _context: ExtensionContext;
  private _language: string;
  private _locale: string;
  private _mode: ExtensionMode;
  private _operatingSystem: string;
  private _tableauVersion: string;

  public constructor(extensionEnvironment: ExtensionEnvironment) {
    this._apiVersion = ApiVersion.Instance && ApiVersion.Instance.formattedValue; // maj.min.fix (no build)
    this._context = EnumMappings.extensionContext.convert(extensionEnvironment.extensionContext);
    this._language = extensionEnvironment.extensionLanguage;
    this._locale = extensionEnvironment.extensionLocale;
    this._mode = EnumMappings.extensionMode.convert(extensionEnvironment.extensionMode);
    this._operatingSystem = extensionEnvironment.operatingSystem;
    this._tableauVersion = extensionEnvironment.tableauVersion;
  }

  public get apiVersion(): string {
    return this._apiVersion;
  }

  public get context(): ExtensionContext {
    return this._context;
  }

  public get language(): string {
    return this._language;
  }

  public get locale(): string {
    return this._locale;
  }

  public get mode(): ExtensionMode {
    return this._mode;
  }

  public get operatingSystem(): string {
    return this._operatingSystem;
  }

  public get tableauVersion(): string {
    return this._tableauVersion;
  }
}
