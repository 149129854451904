import { ExternalContractVersionConverter } from './ExternalContractVersionConverter';
import { ExecuteResponse, ExecuteParameters, Notification, VerbId, VersionNumber } from '../../JsApiInternalContract';
import * as Translations from './ExternalVersionTranslations';

// tslint:disable:no-any
/**
 * The version converter is designed to allow the platform and external modules
 * to seemlessly communicate over two different versions of the internal API. This converter
 * supports external's version(minor) >= platform's version(minor). When executing
 * commands, it is used to downgrade the external representation to what platform knows on the way in
 * and upgrade the representations on the way out.
 */
export class ExternalStackingVersionConverter implements ExternalContractVersionConverter {

  /**
   * Creates a new instance of the StackingVersionConverter
   *
   * @param _externalVersion The version of the internal contract api-external-js is using
   * @param _platformVersion The version of the internal contract the api-platform-js is using
   * @param _downgradeExecuteCallTranslations Ordered list of the translations to perform when downgrading cmd parameters
   * @param _upgradeExecuteReturnTranslations Ordered list of upgrade translations to perform after a cmd is executed
   * @param _upgradeNotificationTranslations Ordered list of upgrade notfications to perform on events
   */
  public constructor(
    private _externalVersion: VersionNumber,
    private _platformVersion: VersionNumber,
    private _downgradeExecuteCallTranslations: Array<Translations.DowngradeExecuteCall>,
    private _upgradeExecuteReturnTranslations: Array<Translations.UpgradeExecuteReturn>,
    private _upgradeNotificationTranslations: Array<Translations.UpgradeNotification>) {


    if (this._externalVersion.major > this._platformVersion.major) {
      throw new Error(`Cannot convert between external version ${this._externalVersion.major}
      and ${this._platformVersion.major}`);
    }
  }

  public downgradeExecuteCall(verb: any, parameters: any): { verb: VerbId; parameters: ExecuteParameters; } {
    // Perform the downgrade of the verb and parameters to the level that platform is using
    let downgraded = { verb: verb, parameters: parameters };
    for (const downgradeTranslation of this._downgradeExecuteCallTranslations) {
      downgraded = downgradeTranslation(downgraded.verb, downgraded.parameters);
    }

    return downgraded;
  }

  public upgradeExecuteReturn(executeResponse: ExecuteResponse): ExecuteResponse {
    // Perform the upgrade of the response to what the external module is expecting
    let upgraded = executeResponse;
    for (const upgradeTranslation of this._upgradeExecuteReturnTranslations) {
      upgraded = upgradeTranslation(upgraded);
    }

    return upgraded;
  }

  public upgradeNotification(notification: Notification): Notification {
    // Perform the upgrade of notification to what the external module is expecting
    let upgraded = notification;
    for (const upgradeNotification of this._upgradeNotificationTranslations) {
      upgraded = upgradeNotification(upgraded);
    }

    return upgraded;
  }
}
