import { DataType } from '../ExternalContract/Namespaces/Tableau';
import * as Contract from '../SharedApiExternalContract';

import { EventListenerManager } from './EventListenerManager';
import { ParameterImpl } from './Impl/ParameterImpl';

/**
 * Implementation of the Parameter contract. Calls down to the impl
 * class for almost all of the work it does.
 */
export class Parameter extends EventListenerManager implements Contract.Parameter {
  public constructor(private parameterImpl: ParameterImpl, sheet: Contract.Sheet) {
    super();

    // Initialize our event handling for this class
    this.parameterImpl.initializeEvents(sheet).forEach(e => this.addNewEventType(e));
  }

  public get name(): string {
    return this.parameterImpl.name;
  }

  public get currentValue(): Contract.DataValue {
    return this.parameterImpl.currentValue;
  }

  public get dataType(): DataType {
    return this.parameterImpl.dataType;
  }

  public get allowableValues(): Contract.ParameterDomainRestriction {
    return this.parameterImpl.allowableValues;
  }

  public get id(): string {
    return this.parameterImpl.id;
  }

  public changeValueAsync(newValue: string | number | boolean | Date): Promise<Contract.DataValue> {
    return this.parameterImpl.changeValueAsync(newValue);
  }
}
