import { FieldAggregationType, FieldRoleType, ColumnType } from '../ExternalContract/Namespaces/Tableau';
import * as Contract from '../SharedApiExternalContract';

import { FieldImpl } from './Impl/FieldImpl';

import { ErrorHelpers } from './Utils/ErrorHelpers';

export class Field implements Contract.Field {
  public constructor(private _fieldImpl: FieldImpl) { }

  public get name(): string {
    return this._fieldImpl.name;
  }

  public get id(): string {
    return this._fieldImpl.id;
  }

  public get description(): string | undefined {
    return this._fieldImpl.description;
  }

  public get aggregation(): FieldAggregationType {
    return this._fieldImpl.aggregation;
  }

  public get dataSource(): Contract.DataSource {
    return this._fieldImpl.dataSource;
  }

  public get role(): FieldRoleType {
    return this._fieldImpl.role;
  }

  public get isHidden(): boolean {
    return this._fieldImpl.isHidden;
  }

  public get isGenerated(): boolean {
    return this._fieldImpl.isGenerated;
  }

  public get isCalculatedField(): boolean {
    return this._fieldImpl.isCalculatedField;
  }

  public get columnType(): ColumnType {
    throw ErrorHelpers.apiNotImplemented('Field.columnType');
  }

  public get isCombinedField(): boolean {
    return this._fieldImpl.isCombinedField;
  }
}
