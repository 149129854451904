import { EnumConverter } from '../Utils/EnumConverter';
import {
  FilterDomainType as ExternalDomainType,
  FilterNullOption as ExternalNullOption,
  FilterUpdateType as ExternalFilterUpdateType,
  ZoneVisibilityType
} from '../../ExternalContract/Namespaces/Tableau';

import {
  FilterDomainType as InternalDomainType,
  FilterNullOption as InternalNullOption,
  FilterUpdateType as InternalFilterUpdateType
} from '@tableau/api-internal-contract-js';


/* tslint:disable:typedef - Disable this to make declaring these classes a bit easier */
/**
 * Maps enums used by the external-api-contract to the enums used
 * in the internal-api-contract, which developers code against.
 */
export class ExternalToInternalEnumMappings {
  public static filterDomainType = new EnumConverter<ExternalDomainType, InternalDomainType>({
    [ExternalDomainType.Relevant]: InternalDomainType.Relevant,
    [ExternalDomainType.Database]: InternalDomainType.Database
  });

  public static nullOptions = new EnumConverter<ExternalNullOption, InternalNullOption>({
    [ExternalNullOption.AllValues]: InternalNullOption.AllValues,
    [ExternalNullOption.NonNullValues]: InternalNullOption.NonNullValues,
    [ExternalNullOption.NullValues]: InternalNullOption.NullValues
  });

  public static filterUpdateType = new EnumConverter<ExternalFilterUpdateType, InternalFilterUpdateType>({
    [ExternalFilterUpdateType.Add]: InternalFilterUpdateType.Add,
    [ExternalFilterUpdateType.All]: InternalFilterUpdateType.All,
    [ExternalFilterUpdateType.Remove]: InternalFilterUpdateType.Remove,
    [ExternalFilterUpdateType.Replace]: InternalFilterUpdateType.Replace
  });

  public static setVisibilityType = new EnumConverter<ZoneVisibilityType, Boolean>({
    [ZoneVisibilityType.Show]: true,
    [ZoneVisibilityType.Hide]: false
  });
}
/* tslint:enable:typedef */
