import { SheetType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { DashboardObject } from '../DashboardObject';
import {
  DashboardObjectType,
  ExtensionDashboardInfo,
  SheetPath,
  VisualId
} from '@tableau/api-internal-contract-js';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';
import { Point } from '../Point';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { ZoneService } from '../Services/ZoneService';
import { Size } from '../Size';
import { Worksheet } from '../Worksheet';
import { WorksheetImpl } from './WorksheetImpl';

export class DashboardImpl extends SheetImpl {
  private _worksheets: Array<Contract.Worksheet>;
  private _objects: Array<Contract.DashboardObject>;

  public constructor(private _info: ExtensionDashboardInfo, private _sheetPath: SheetPath) {
    super(new SheetInfoImpl(_info.name, SheetType.Dashboard, new Size(_info.size.h, _info.size.w)));
  }

  public get worksheets(): Array<Contract.Worksheet> {
    return this._worksheets;
  }

  public get objects(): Array<Contract.DashboardObject> {
    return this._objects;
  }

  public initializeWithPublicInterfaces(dashboard: Contract.Dashboard): void {
    ErrorHelpers.verifyInternalValue(dashboard, 'dashboard');

    this._worksheets = new Array<Worksheet>();
    this._objects = new Array<Contract.DashboardObject>();

    // Process all the zones which are contained in this dashboard
    for (const zone of this._info.zones) {
      let worksheet: Worksheet | undefined = undefined;

      const zoneSize = new Size(zone.height, zone.width);

      if (zone.zoneType === DashboardObjectType.Worksheet) {
        // zone.sheetInfo was not initialized prior to internal-contract 1.6.0
        let worksheetName = zone.sheetInfo ? zone.sheetInfo.name : zone.name;
        const sheetInfo = new SheetInfoImpl(worksheetName, SheetType.Worksheet, zoneSize);
        const vizId: VisualId = {
          worksheet: worksheetName,
          dashboard: this._info.name,
          storyboard: this._sheetPath.storyboard,
          flipboardZoneID: this._sheetPath.flipboardZoneID,
          storyPointID: this._sheetPath.storyPointID
        };

        const worksheetImpl = new WorksheetImpl(sheetInfo, vizId, dashboard);
        worksheet = new Worksheet(worksheetImpl);
        this._worksheets.push(worksheet);
      }

      const zonePoint = new Point(zone.x, zone.y);

      const dashboardObject = new DashboardObject(
        dashboard,
        InternalToExternalEnumMappings.dashboardObjectType.convert(zone.zoneType),
        zonePoint,
        zoneSize,
        worksheet,
        zone.name,
        (zone.isFloating !== undefined) ? zone.isFloating : false,  // before 1.6.0 we didn't have isFloating, so we assume false
        (zone.isVisible !== undefined) ? zone.isVisible : true,     // before 1.6.0 we didn't have isVisible, so we assume true
        zone.zoneId
      );

      this._objects.push(dashboardObject);
    }
  }

  public setZoneVisibilityAsync(zoneVisibilityMap: Contract.ZoneVisibilityMap): Promise<void> {
    const zoneService = ApiServiceRegistry.instance.getService<ZoneService>(
      ServiceNames.Zone);

    return zoneService.setVisibilityAsync(/*Dashboard Name*/ this.name, this.objects, zoneVisibilityMap);
  }
}
