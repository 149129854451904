import { TableauEventType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';

import { TableauEvent } from './TableauEvent';

export class TableauSheetEvent extends TableauEvent implements Contract.TableauSheetEvent {
  private _sheet: Contract.Sheet;

  public get sheet(): Contract.Sheet {
    return this._sheet;
  }

  public constructor(type: TableauEventType, sheet: Contract.Sheet) {
    super(type);

    this._sheet = sheet;
  }
}
