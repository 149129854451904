import {
  InternalApiDispatcher,
} from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry } from '../../ApiShared';

import { InitializationServiceImpl } from './Impl/InitializationServiceImpl';
import { SettingsServiceImpl } from './Impl/SettingsServiceImpl';
import { UIServiceImpl } from './Impl/UIServiceImpl';

export function registerAllExtensionsServices(dispatcher: InternalApiDispatcher): void {
  ApiServiceRegistry.instance.registerService(new SettingsServiceImpl(dispatcher));
  ApiServiceRegistry.instance.registerService(new UIServiceImpl(dispatcher));
}

export function registerInitializationExtensionsServices(dispatcher: InternalApiDispatcher): void {
  ApiServiceRegistry.instance.registerService(new InitializationServiceImpl(dispatcher));
}
