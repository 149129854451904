import { ErrorCodes } from '../../ExternalContract/Namespaces/Tableau';
import { TableauError } from '../TableauError';

/**
 * Base interface for an api service
 */
export interface ApiService {
  /**
   * Gets the name for this service.
   */
  readonly serviceName: string;
}

/**
 * Collection of service name which will be registered in the api-shared project
 */
export const enum ServiceNames {
  DataSourceService = 'data-source-service',
  GetData = 'get-data-service',
  Filter = 'filter-service',
  Notification = 'notification-service',
  Parameters = 'parameters-service',
  Selection = 'selection-service',
  Zone = 'zone-service'
}

/**
 * Do some globabl declarations so we can create a singleton on the window object
 */
declare global {
  interface Window { __tableauApiServiceRegistry: ServiceRegistry | undefined; }
}

export interface ServiceRegistry {
  /**
   * Registers a new service into the service registry. Any existing one will
   * be overwritten. the service is registered under service.serviceName
   *
   * @param {ApiService} service The servive to register
   */
  registerService(service: ApiService): void;

  /**
   * Retrieves the given service from the registry. If there is not a
   * service registered under that name, throws and error
   *
   * @template T The type of the service
   * @param {string} serviceName The name of the service.
   * @returns {T} The requested service
   */
  getService<T extends ApiService>(serviceName: string): T;
}

class ServiceRegistryImpl implements ServiceRegistry {
  private _services: { [serviceName: string]: ApiService; };

  public constructor() {
    this._services = {};
  }

  public registerService(service: ApiService): void {
    this._services[service.serviceName] = service;
  }

  public getService<T extends ApiService>(serviceName: string): T {
    if (!this._services.hasOwnProperty(serviceName)) {
      throw new TableauError(ErrorCodes.InternalError, `Service not registered: ${serviceName}`);
    }

    return this._services[serviceName] as T;
  }
}

/**
 * static class used for getting access to the single instance
 * of the ApiServiceRegistry
 */
export class ApiServiceRegistry {
  /**
   * Gets the singleton instance of the ServiceRegistry
   */
  public static get instance(): ServiceRegistry {
    if (!window.__tableauApiServiceRegistry) {
      ApiServiceRegistry.setInstance(new ServiceRegistryImpl());
    }

    if (!window.__tableauApiServiceRegistry) {
      throw new TableauError(ErrorCodes.InternalError, 'Service registry failed');
    }

    return window.__tableauApiServiceRegistry;
  }

  /**
   * Helper method to override the registry instance. Can be used by unit tests
   *
   * @param {ServiceRegistry} serviceRegistry The new registry
   */
  public static setInstance(serviceRegistry?: ServiceRegistry): void {
    window.__tableauApiServiceRegistry = serviceRegistry;
  }

  // Private to avoid anyone constructing this
  private constructor() { }
}
