import { DataType, TableauEventType, PeriodType, ParameterValueType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';
import { NotificationId, ParameterInfo } from '@tableau/api-internal-contract-js';

import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';
import { ParameterChangedEvent } from '../Events/ParameterChangedEvent';
import { DataValue } from '../Models/GetDataModels';
import { NotificationService } from '../Services/NotificationService';
import { ParametersService } from '../Services/ParametersService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { SingleEventManager } from '../SingleEventManager';
import { SingleEventManagerImpl } from './SingleEventManagerImpl';
import { DataValueFactory } from '../Utils/DataValueFactory';

import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { Param } from '../Utils/Param';

export class ParameterImpl {
  private _allowableValues: Contract.ParameterDomainRestriction;
  private _globalFieldName: string;
  private _parameterInfo: ParameterInfo;

  public constructor(parameterInfo: ParameterInfo) {
    this.setParameterInfo(parameterInfo);
  }

  public get name(): string {
    return this._parameterInfo.name;
  }

  public get currentValue(): DataValue {
    return DataValueFactory.MakeParameterDataValue(this._parameterInfo.currentValue, this._parameterInfo.dataType);
  }

  public get dataType(): DataType {
    return InternalToExternalEnumMappings.dataType.convert(this._parameterInfo.dataType);
  }

  public get id(): string {
    return this._globalFieldName;
  }

  public get allowableValues(): Contract.ParameterDomainRestriction {
    return this._allowableValues;
  }

  public changeValueAsync(newValue: string | number | boolean | Date): Promise<DataValue> {
    ErrorHelpers.verifyParameter(newValue, 'newValue');

    let coercedValue = Param.serializeParameterValue(newValue);
    const parametersService = ApiServiceRegistry.instance.getService<ParametersService>(ServiceNames.Parameters);
    return parametersService.changeParameterValueAsync(this._globalFieldName, coercedValue).then(parameterInfo => {
      this.setParameterInfo(parameterInfo);
      return this.currentValue;
    });
  }

  /**
   * Helper method which goes through and registers each event type this impl knows about
   * with the NotificationService. It returns an array of SingleEventManager objects which
   * can then be passed to an EventListenerManager to handle user registration / unregistration.
   *
   * @param sheet The sheet object which will be included with the event notifications
   * @returns {Array<SingleEventManager>} Collection of event managers to pass to an EventListenerManager
   */
  public initializeEvents(sheet: Contract.Sheet): Array<SingleEventManager> {
    ErrorHelpers.verifyInternalValue(sheet, 'sheet');

    const results = new Array<SingleEventManager>();
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.instance.getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      return results;
    }

    // Initialize all of the event managers we'll need (one for each event type)
    const parameterEvent = new SingleEventManagerImpl<ParameterChangedEvent>(TableauEventType.ParameterChanged);
    notificationService.registerHandler(NotificationId.ParameterChanged, (model) => {
      const fieldName = model as string;
      return fieldName === this._globalFieldName;
    }, (fieldName: string) => {
      parameterEvent.triggerEvent(() => new ParameterChangedEvent(fieldName, sheet));
    });

    results.push(parameterEvent);

    return results;
  }

  private setParameterInfo(parameterInfo: ParameterInfo): void {
    this._parameterInfo = parameterInfo;
    this._globalFieldName = parameterInfo.fieldName;

    const type = InternalToExternalEnumMappings.allowableValues.convert(parameterInfo.allowableValuesType);
    let listValues: Array<DataValue> | undefined;
    let minValue: DataValue | undefined;
    let maxValue: DataValue | undefined;
    let stepSize: number | undefined;
    let dateStepPeriod: PeriodType | undefined;

    if (type === ParameterValueType.List) {
      const values = parameterInfo.allowableValues || [];
      listValues = values.map(val => DataValueFactory.MakeParameterDataValue(val, parameterInfo.dataType));
    } else if (type === ParameterValueType.Range) {
      minValue = parameterInfo.minValue && DataValueFactory.MakeParameterDataValue(parameterInfo.minValue, parameterInfo.dataType);
      maxValue = parameterInfo.maxValue && DataValueFactory.MakeParameterDataValue(parameterInfo.maxValue, parameterInfo.dataType);
      stepSize = parameterInfo.stepSize;
      dateStepPeriod = parameterInfo.dateStepPeriod &&
        InternalToExternalEnumMappings.dateStepPeriod.convert(parameterInfo.dateStepPeriod);
    }

    this._allowableValues = {
      type: type,
      allowableValues: listValues,
      minValue: minValue,
      maxValue: maxValue,
      stepSize: stepSize,
      dateStepPeriod: dateStepPeriod
    };
  }
}
