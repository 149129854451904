import * as Contract from '../../SharedApiExternalContract';
import { VisualId } from '@tableau/api-internal-contract-js';

import { ApiService } from './ServiceRegistry';

/**
 * Defines which type of getData call to make.
 */
export enum GetDataType {
  Summary = 'summary',
  Underlying = 'underlying'
}

/**
 * Service for implementing the logic for various getData calls
 *
 * @interface GetDataService
 * @extends {ApiService}
 */
export interface GetDataService extends ApiService {

  /**
   * Gets the limit of rows for getUnderlyingDataAsync
   */
  getMaxRowLimit(): number;

  /**
   * Gets the underlying data for a particular visual
   *
   * @param {VisualId} visualId  The visual id
   * @param {GetDataType} getType  The type of getData call to make
   * @param {boolean} ignoreAliases  Whether or not aliases should be ignored
   * @param {boolean} ignoreSelection  Whether or not selection should be ignored
   * @param {boolean} includeAllColumns  Should all columns be included
   * @param {number} maxRows  Maximum number of rows to return
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getUnderlyingDataAsync(
    visualId: VisualId,
    getType: GetDataType,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    maxRows: number): Promise<Contract.DataTable>;

  /**
   * Gets the currently selected marks for a given visual
   *
  * @param {VisualId} visualId  The visual id
  * @returns {Promise<ActiveMarks>}  Collection of data tables with the active marks
  */
  getSelectedMarksAsync(visualId: VisualId): Promise<Contract.MarksCollection>;

  /**
   * Gets the currently highlighted marks for a given visual
   *
  * @param {VisualId} visualId  The visual id
  * @returns {Promise<ActiveMarks>}  Collection of data tables with the active marks
  */
  getHighlightedMarksAsync(visualId: VisualId): Promise<Contract.MarksCollection>;

  /**
   * @param {string} datasourceId  The id of the datasource
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {number} maxRows The maximum number of rows to retrieve
   * @param {Array<string>} columnsToInclude  Collection of column captions which should be returned. Empty means all columns
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getDataSourceDataAsync(
    datasourceId: string,
    ignoreAliases: boolean,
    maxRows: number,
    columnsToInclude: Array<string>): Promise<Contract.DataTable>;

  /**
   * @param {string} datasourceId  The id of the datasource
   * @param {string} logicalTableId  The id of the logcial table
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {number} maxRows The maximum number of rows to retrieve
   * @param {Array<string>} columnsToInclude  Collection of column captions which should be returned. Empty means all columns
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getLogicalTableDataAsync(
    datasourceId: string,
    logicalTableId: string,
    ignoreAliases: boolean,
    maxRows: number,
    columnsToInclude: Array<string>): Promise<Contract.DataTable>;

  /**
   * @param {VisualId} visualId  The visual id
   * @param {string} logicalTableId  The id of the logcial table
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {boolean} ignoreSelection  Whether selections in this viz should be ignored in the returned data
   * @param {boolean} includeAllColumns  Whether to include all columns or only the referenced columns
   * @param {number} maxRows The maximum number of rows to retrieve
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getUnderlyingTableDataAsync(
    visualId: VisualId,
    logicalTableId: string,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    maxRows: number): Promise<Contract.DataTable>;
}
