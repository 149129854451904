import { FilterUpdateType, SelectionUpdateType } from '../ExternalContract/Namespaces/Tableau';
import * as Contract from '../SharedApiExternalContract';

import { Sheet } from './Sheet';
import { WorksheetImpl } from './Impl/WorksheetImpl';

export class Worksheet extends Sheet implements Contract.Worksheet {
  public constructor(private _worksheetImpl: WorksheetImpl) {
    super(_worksheetImpl);

    // Call to initialize events and then call down to the event listener manager to handle things
    this._worksheetImpl.initializeEvents(this).forEach(e => this.addNewEventType(e));
  }

  public get parentDashboard(): Contract.Dashboard {
    return this._worksheetImpl.parentDashboard;
  }

  public applyFilterAsync(
    fieldName: string, values: Array<string>, updateType: FilterUpdateType, options: Contract.FilterOptions): Promise<string> {
    return this._worksheetImpl.applyFilterAsync(fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRangeFilterAsync(fieldName, filterOptions);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    return this._worksheetImpl.clearFilterAsync(fieldName);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    return this._worksheetImpl.getDataSourcesAsync();
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._worksheetImpl.getFiltersAsync();
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getSelectedMarksAsync();
  }

  public getHighlightedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getHighlightedMarksAsync();
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getSummaryDataAsync(options);
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    console.warn('Worksheet.getUnderlyingDataAsync is deprecated. Please use ' +
      'Worksheet.getUnderlyingTablesAsync and Worksheet.getUnderlyingTableDataAsync');
    return this._worksheetImpl.getUnderlyingDataAsync(options);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    return this._worksheetImpl.getUnderlyingTablesAsync();
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options: Contract.GetUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    return this._worksheetImpl.getUnderlyingTableDataAsync(logicalTableId, options);
  }

  public clearSelectedMarksAsync(): Promise<void> {
    return this._worksheetImpl.clearSelectedMarksAsync();
  }

  public selectMarksByIDAsync(marksInfo: Array<Contract.MarkInfo>, updateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByIdAsync(marksInfo, updateType);
  }

  public selectMarksByValueAsync(selections: Array<Contract.SelectionCriteria>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByValueAsync(selections, selectionUpdateType);
  }

  public selectMarksByIdAsync(selections: Array<Contract.MarkInfo>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByIdAsync(selections, selectionUpdateType);
  }
}
