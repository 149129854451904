import { TableauEventType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';

import { TableauSheetEvent } from './TableauSheetEvent';

export class TableauWorksheetEvent extends TableauSheetEvent implements Contract.TableauWorksheetEvent {
  public get worksheet(): Contract.Worksheet {
    return this._worksheet;
  }

  public constructor(type: TableauEventType, protected _worksheet: Contract.Worksheet) {
    super(type, _worksheet);
  }
}
