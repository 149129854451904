import { MarkType, DataType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';

export class DataTable implements Contract.DataTable {
  private _name: string;

  public constructor(
    private _data: Array<Array<Contract.DataValue>>,
    private _columns: Array<Contract.Column>,
    private _totalRowCount: number,
    private _isTotalRowCountLimited: boolean,
    private _isSummaryData: boolean,
    private _marksInfo?: Array<MarkInfo>) {
    // TODO: get rid of this in redesign.
    this._name = _isSummaryData ? 'Summary Data Table' : 'Underlying Data Table';
  }

  public get name(): string {
    return this._name;
  }

  public get data(): Array<Array<Contract.DataValue>> {
    return this._data;
  }

  public get columns(): Array<Contract.Column> {
    return this._columns;
  }

  public get marksInfo(): Array<Contract.MarkInfo> | undefined {
    return this._marksInfo;
  }

  public get totalRowCount(): number {
    return this._totalRowCount;
  }

  public get isTotalRowCountLimited(): boolean {
    return this._isTotalRowCountLimited;
  }

  public get isSummaryData(): boolean {
    return this._isSummaryData;
  }
}

export class MarkInfo implements Contract.MarkInfo {
  public constructor(
    private _type: MarkType,
    private _color: string,
    private _tupleId?: Number
  ) { }

  public get type(): MarkType {
    return this._type;
  }

  public get color(): string {
    return this._color;
  }

  public get tupleId(): Number | undefined {
    return this._tupleId;
  }
}

export class Column implements Contract.Column {
  public constructor(
    private _fieldName: string,
    private _dataType: DataType, // TODO: this shoudl be an enum type
    private _isReferenced: boolean,
    private _index: number) { }

  public get fieldName(): string {
    return this._fieldName;
  }

  public get dataType(): DataType {
    return this._dataType;
  }

  public get isReferenced(): boolean {
    return this._isReferenced;
  }

  public get index(): number {
    return this._index;
  }
}

export class DataValue implements Contract.DataValue {
  /* tslint:disable:no-any */
  public constructor(
    private _value: any,
    private _nativeValue: any,
    private _formattedValue: string) { }

  public get value(): any {
    return this._value;
  }

  public get nativeValue(): any {
    return this._nativeValue;
  }

  public get formattedValue(): string {
    return this._formattedValue;
  }
  /* tslint:enable:no-any */
}
