import { ErrorCodes } from '../../ExternalContract/Namespaces/Tableau';
import {
  ApiServiceRegistry,
  Dashboard,
  DashboardImpl,
  doCrossFrameBootstrap,
  NotificationService,
  registerAllSharedServices,
  ServiceNames
} from '../../ApiShared';
import { DashboardContent } from '../Namespaces/DashboardContent';
import { Environment } from '../Namespaces/Environment';
import { ExtensionsServiceNames } from '../Services/ExtensionsServiceNames';
import { InitializationService } from '../Services/InitializationService';
import { registerAllExtensionsServices, registerInitializationExtensionsServices } from '../Services/RegisterAllExtensionsServices';
import { Settings } from '../Namespaces/Settings';
import { SettingsImpl } from './SettingsImpl';
import { TableauError } from '../../ApiShared';
import { UI } from '../Namespaces/UI';
import { UIImpl } from './UIImpl';
import { ApiVersion } from '../../ApiShared/ApiVersion';
import { VersionedExternalApiDispatcher } from '../../VersionedExternalApiDispatcher';

import {
  ContextMenuEvent,
  ExtensionDashboardInfo,
  ExtensionSettingsInfo,
  InternalApiDispatcherFactory,
  NotificationId,
  SheetPath,
  INTERNAL_CONTRACT_VERSION,
  InitializationOptions,
  InternalApiDispatcher,
} from '@tableau/api-internal-contract-js';
import { LegacyInternalApiDispatcherHolder } from './LegacyInternalApiDispatcherHolder';


export type CallbackMap = { [key: string]: () => {} };

export class ExtensionsImpl {
  private _initializationPromise: Promise<string>;

  public dashboardContent: DashboardContent;
  public environment: Environment;
  public settings: Settings;
  public ui: UI;

  public initializeAsync(isExtensionDialog: boolean, contextMenuCallbacks?: CallbackMap): Promise<string> {
    if (!this._initializationPromise) {
      this._initializationPromise = new Promise<string>((resolve, reject) => {
        const initOptions: InitializationOptions = { isAlpha: ApiVersion.Instance.isAlpha };
        // First thing we want to do is check to see if there is a desktop dispatcher already registered for us
        if (LegacyInternalApiDispatcherHolder.hasDesktopApiDispatcherPromise(initOptions)) {
          // Running in a pre-2019.3 desktop, use our legacy dispatcher promise
          const desktopDispatcherPromise = LegacyInternalApiDispatcherHolder.getDesktopDispatcherPromise(initOptions);
          desktopDispatcherPromise!.then((dispatcherFactory) =>
            this.onDispatcherReceived(dispatcherFactory, isExtensionDialog, contextMenuCallbacks))
            .then((openPayload) => {
              resolve(openPayload);
            }).catch((error) => {
              reject(error);
            });
        } else {
          // We must be running in server, so we should try to kick of the server dispatcher bootstrapping
          const onDispatcherReceivedCallback = this.onDispatcherReceived.bind(this);
          doCrossFrameBootstrap(window, INTERNAL_CONTRACT_VERSION, initOptions).then((factory: InternalApiDispatcherFactory) => {
            return onDispatcherReceivedCallback(factory, isExtensionDialog, contextMenuCallbacks);
          }).then((openPayload) => {
            resolve(openPayload);
          }).catch((error) => {
            reject(error);
          });
        }
      });
    }

    return this._initializationPromise;
  }

  private onDispatcherReceived(
    dispatcherFactory: InternalApiDispatcherFactory,
    isExtensionDialog: boolean,
    contextMenuFunctions?: CallbackMap): Promise<string> {

    let dispatcher: InternalApiDispatcher = dispatcherFactory(INTERNAL_CONTRACT_VERSION);

    // Call to register all the services which will use the newly initialized dispatcher
    registerInitializationExtensionsServices(dispatcher);

    // Get the initialization service and initialize this extension
    const initializationService = ApiServiceRegistry.instance.getService<InitializationService>(
      ExtensionsServiceNames.InitializationService);

    const callbackMapKeys = (contextMenuFunctions) ? Object.keys(contextMenuFunctions) : [];
    return initializationService.initializeDashboardExtensionsAsync(isExtensionDialog, callbackMapKeys).then<string>(result => {
      if (!result.extensionInstance.locator.dashboardPath) {
        throw new TableauError(ErrorCodes.InternalError, 'Unexpected error during initialization.');
      }

      // If we receive an invalid plaform version, this means that platform is runnning 1.4 or 2.1 and
      // doesn't pass the platform version to external. In this case we assume the platform version to be 1.9
      let platformVersion = result.extensionEnvironment.platformVersion
        ? result.extensionEnvironment.platformVersion
        : { major: 1, minor: 9, fix: 0 };

      // Wrap our existing dispatcher in a dispatcher that can downgrade/upgrade for an older platform.
      if (VersionedExternalApiDispatcher.needsVersionConverter(platformVersion)) {
        dispatcher = new VersionedExternalApiDispatcher(dispatcher, platformVersion);
      }
      // Registration of services must happen before initializing content and environment

      registerAllSharedServices(dispatcher, platformVersion);
      registerAllExtensionsServices(dispatcher);

      this.dashboardContent = this.initializeDashboardContent(
        result.extensionDashboardInfo,
        result.extensionInstance.locator.dashboardPath);

      this.environment = new Environment(result.extensionEnvironment);
      this.settings = this.initializeSettings(result.extensionSettingsInfo);
      this.ui = new UI(new UIImpl());

      // After initialization has completed, setup listeners for the callback functions that
      // are meant to be triggered whenever a context menu item is clicked.
      this.initializeContextMenuCallbacks(contextMenuFunctions);

      // In the normal initialization case, this will be an empty string.  When returning from initializeAsync to the
      // developer, we just ingore that string.  In the case of initializing from an extension dialog, this string
      // is an optional payload sent from the parent extension.
      return result.extensionDialogPayload;
    });
  }

  private initializeDashboardContent(info: ExtensionDashboardInfo, sheetPath: SheetPath): DashboardContent {
    const dashboardImpl = new DashboardImpl(info, sheetPath);
    const dashboard = new Dashboard(dashboardImpl);
    return new DashboardContent(dashboard);
  }

  private initializeSettings(settingsInfo: ExtensionSettingsInfo): Settings {
    const settingsImpl = new SettingsImpl(settingsInfo);
    return new Settings(settingsImpl);
  }

  private initializeContextMenuCallbacks(contextMenuFunctions?: CallbackMap): void {
    const notificationService: NotificationService = ApiServiceRegistry.instance.getService<NotificationService>(ServiceNames.Notification);

    // Unregister function not used since these notifications should be
    // observed for the full lifetime of the extension.
    notificationService.registerHandler(NotificationId.ContextMenuClick, (model) => {
      // Let through any context menu event, these are already filtered on api-core
      // based on the extension locator.
      return true;
    }, (event: ContextMenuEvent) => {
      // Execute the function associated with this context menu ID
      if (contextMenuFunctions) {
        if (!contextMenuFunctions[event.id]) {
          throw new TableauError(ErrorCodes.InternalError, `Received unexpected context menu Id from event: ${event.id}`);
        }

        contextMenuFunctions[event.id]();
      }
    });
  }
}
