import { FilterType, FilterDomainType, PeriodType, DateRangeType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';
import { DataSourceService } from '../Services/DataSourceService';
import { FilterService } from '../Services/FilterService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';


export class Filter implements Contract.Filter {
  public constructor(
    protected _worksheetName: string,
    protected _fieldName: string,
    protected _filterType: FilterType,
    protected _fieldId: string) {
  }

  public get worksheetName(): string {
    return this._worksheetName;
  }

  public get fieldName(): string {
    return this._fieldName;
  }

  public get fieldId(): string {
    return this._fieldId;
  }

  public get filterType(): FilterType {
    return this._filterType;
  }

  public getFieldAsync(): Promise<Contract.Field> {
    const service = ApiServiceRegistry.instance.getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.getFieldAsync(this._fieldId);
  }
}

export class CategoricalFilter extends Filter implements Contract.CategoricalFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    private _appliedValues: Array<Contract.DataValue>,
    private _isExcludeMode: boolean,
    private _isAllSelected?: boolean) {
    super(worksheetName, fieldName, filterType, fieldId);
  }

  public get isAllSelected(): boolean | undefined {
    return this._isAllSelected;
  }

  public get appliedValues(): Array<Contract.DataValue> {
    return this._appliedValues;
  }

  public get isExcludeMode(): boolean {
    return this._isExcludeMode;
  }

  public getDomainAsync(domainType?: FilterDomainType): Promise<Contract.CategoricalDomain> {
    if (!domainType) {
      domainType = FilterDomainType.Relevant;
    }

    ErrorHelpers.verifyEnumValue<FilterDomainType>(domainType, FilterDomainType, 'FilterDomainType');

    const service = ApiServiceRegistry.instance.getService<FilterService>(ServiceNames.Filter);
    return service.getCategoricalDomainAsync(this._worksheetName, this._fieldId, domainType);
  }
}

export class RangeFilter extends Filter implements Contract.RangeFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    private _min: Contract.DataValue,
    private _max: Contract.DataValue,
    private _includeNullValues: boolean) {
    super(worksheetName, fieldName, filterType, fieldId);
  }

  public get minValue(): Contract.DataValue {
    return this._min;
  }

  public get maxValue(): Contract.DataValue {
    return this._max;
  }

  public get includeNullValues(): boolean {
    return this._includeNullValues;
  }

  public getDomainAsync(domainType?: FilterDomainType): Promise<Contract.RangeDomain> {
    const service = ApiServiceRegistry.instance.getService<FilterService>(ServiceNames.Filter);
    if (!domainType) {
      domainType = FilterDomainType.Relevant;
    }

    ErrorHelpers.verifyEnumValue<FilterDomainType>(domainType, FilterDomainType, 'FilterDomainType');

    return service.getRangeDomainAsync(this._worksheetName, this._fieldId, domainType);
  }
}

export class RelativeDateFilter extends Filter implements Contract.RelativeDateFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    private _anchorDate: Contract.DataValue,
    private _periodType: PeriodType,
    private _rangeType: DateRangeType,
    private _rangeN: number) {
    super(worksheetName, fieldName, filterType, fieldId);
  }

  public get anchorDate(): Contract.DataValue {
    return this._anchorDate;
  }

  public get periodType(): PeriodType {
    return this._periodType;
  }

  public get rangeType(): DateRangeType {
    return this._rangeType;
  }

  public get rangeN(): number {
    return this._rangeN;
  }
}

export class CategoricalDomain implements Contract.CategoricalDomain {
  public constructor(
    private _values: Array<Contract.DataValue>,
    private _domainType: FilterDomainType) {
  }

  public get values(): Array<Contract.DataValue> {
    return this._values;
  }

  public get type(): FilterDomainType {
    return this._domainType;
  }
}

export class RangeDomain implements Contract.RangeDomain {
  public constructor(
    private _min: Contract.DataValue,
    private _max: Contract.DataValue,
    private _domainType: FilterDomainType) {
  }

  public get type(): FilterDomainType {
    return this._domainType;
  }

  public get min(): Contract.DataValue {
    return this._min;
  }

  public get max(): Contract.DataValue {
    return this._max;
  }
}
