import * as Contract from '../../ExtensionsApiExternalContract';

/**
 * Implementation of the external DashboardContent namespace.
 * This does not follow the Impl pattern as DashboardContent is
 * currently just a (single) property bag.
 */
export class DashboardContent implements Contract.DashboardContent {
  public constructor(private _dashboard: Contract.Dashboard) { }

  public get dashboard(): Contract.Dashboard {
    return this._dashboard;
  }
}
