import { ErrorCodes } from '../../ExternalContract/Namespaces/Tableau';

import { Param } from './Param';

import { TableauError } from '../TableauError';
import { DashboardObject } from '../DashboardObject';

interface EnumLike {
  toString(): string;
}

/**
 * This class is used to construct common errors throughout the external
 * projects (api-shared, extensions-api, etc.).  It has some duplication with
 * the ErrorHelpers class in api-core, but is separate due to the need to throw
 * an external TableauError vs. an InternalTableauError.
 */
export class ErrorHelpers {
  /**
   * Throws with code InternalError.
   *
   * @param apiName name of api that was called.
   */
  public static apiNotImplemented(apiName: string): TableauError {
    return new TableauError(ErrorCodes.InternalError, `${apiName} API not yet implemented.`);
  }

  /**
   * Throws an internal error if argument is null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyInternalValue(argumentValue: any, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined) {
      throw new TableauError(ErrorCodes.InternalError, `${argumentValue} is invalid value for: ${argumentName}`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyParameter(argumentValue: any, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined) {
      throw new TableauError(ErrorCodes.InvalidParameter, `${argumentValue} is invalid value for parameter: ${argumentName}`);
    }
  }

  /**
  * Throws an InvalidParameter error if argument is not the specified type.
  * For objects, it just tests that it is an object
  *
  * @param argumentValue value to verify
  * @param expectedType expected result of typeof
  * @param argumentName name of argument to verify
  */
  /*tslint:disable-next-line */
  public static verifyParameterType(argumentValue: any, expectedType: string, argumentName: string) {
    if (typeof (argumentValue) !== expectedType) {
      throw new TableauError(ErrorCodes.InvalidParameter, `${argumentValue} has invalid type for parameter: ${argumentName}.`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is empty string, null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyStringParameter(argumentValue: string, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined || argumentValue === '') {
      throw new TableauError(ErrorCodes.InvalidParameter, `${argumentValue} is invalid value for paramter: ${argumentName}`);
    }
  }

  /**
   * Verifies passed value is a valid value for that enum.
   * Throws an InvalidParameter error if the enum value is not valid.
   *
   * String enums are {string : string} dictionaries which are not reverse mappable
   * This is an ugly workaround
   *
   * @param enumValue value to verify
   * @param enumType enum to verify against
   * @param enumName enum name for clear error message
   */
  /*tslint:disable-next-line */
  public static verifyEnumValue<EnumType extends EnumLike>(enumValue: EnumType, enumType: any, enumName: string) {
    let isValid: boolean = false;
    Object.keys(enumType).forEach((enumKey) => {
      if (enumType[enumKey] === enumValue.toString()) {
        isValid = true;
      }
    });

    if (!isValid) {
      throw new TableauError(ErrorCodes.InvalidParameter, `${enumValue} is invalid value for enum: ${enumName}.`);
    }
  }

  /**
   * Verifies the params min and max for applying range filter.
   * Throws with error code InvalidParameter if range is invalid.
   *
   * @param min range min
   * @param max range max
   */
  /*tslint:disable-next-line */
  public static verifyRangeParamType(min: any, max: any): void {
    if (!min && !max) {
      throw new TableauError(ErrorCodes.InvalidParameter,
        'Unexpected invalid param value, at least one of min or max is required.');
    }

    if (min && !Param.isTypeNumber(min) && !Param.isTypeDate(min)) {
      throw new TableauError(ErrorCodes.InvalidParameter,
        'Unexpected invalid param value, only Date and number are allowed for parameter min.');
    }

    if (max && !Param.isTypeNumber(max) && !Param.isTypeDate(max)) {
      throw new TableauError(ErrorCodes.InvalidParameter,
        'Unexpected invalid param value, only Date and number are allowed for parameter max.');
    }

    if (min && max && typeof (min) !== typeof (max)) {
      throw new TableauError(ErrorCodes.InvalidParameter,
        'Unexpected invalid param value, parameters min and max should be of the same type.');
    }
  }

  /**
   * Verifies that the zoneId is present in the current dashboard and is Floating.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardObjects An array of all DashboardObjects in the current dashboard
   * @param zoneID ZoneId to be validated
   */
  public static verifyZoneIsValid(dashboardObjects: Array<DashboardObject>, zoneID: number): void {

    let isValid = dashboardObjects.some((dashboardObject) => {
      return dashboardObject.id === zoneID && dashboardObject.isFloating;
    });

    if (!isValid) {
      throw new TableauError(ErrorCodes.InvalidParameter,
        `Unexpected invalid param value, Zone Id: ${zoneID} is either not present or is a fixed zone.`);
    }
  }
}
