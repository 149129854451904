import * as Contract from '../../../SharedApiExternalContract';
import {
  DimensionSelectionModel,
  HierarchicalSelectionModel,
  RangeSelectionModel,
  SelectionModelsContainer,
  TupleSelectionModel,
  ValueSelectionModel
} from '../../Models/SelectionModels';
import { ErrorCodes, FilterNullOption, SelectionUpdateType } from '../../../ExternalContract/Namespaces/Tableau';
import {
  ExecuteParameters,
  ParameterId,
  QuantitativeIncludedValues,
  SelectionUpdateType as SelectionUpdateTypeInternal,
  VerbId,
  VisualId
} from '@tableau/api-internal-contract-js';
import { Param } from '../../Utils/Param';
import { SelectionService } from '../SelectionService';
import { ServiceImplBase } from './ServiceImplBase';
import { ServiceNames } from '../ServiceRegistry';
import { TableauError } from '../../TableauError';

export class SelectionServiceImpl extends ServiceImplBase implements SelectionService {
  public get serviceName(): string {
    return ServiceNames.Selection;
  }

  /**
   * Method to clear all the selected marks for the given worksheet.
   *
   * @param visualId
   */
  public clearSelectedMarksAsync(visualId: VisualId): Promise<void> {
    const parameters: ExecuteParameters = { [ParameterId.VisualId]: visualId };
    return this.execute(VerbId.ClearSelectedMarks, parameters).then<void>(response => {
      return; // Expecting an empty model and hence the void response.
    });
  }

  /**
   * Method to select marks for the given worksheet.
   *
   * @param visualId
   * @param selectionCriteria
   * @param selectionUpdateType
   */
  public selectMarksByValueAsync(visualId: VisualId,
    selectionCriterias: Array<Contract.SelectionCriteria>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    if (selectionCriterias.length === 0) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'Selection criteria missing for selecting marks by value');
    }

    const selectionType: string = this.validateSelectionUpdateType(selectionUpdateType);
    let selectionCriteriaType: SelectionCriteriaType = this.validateSelectionCriteria(selectionCriterias[0]);
    let selectionModelContainer: SelectionModelsContainer = this.parseSelectionMarks(selectionCriterias, selectionCriteriaType);

    const parameters: ExecuteParameters = {
      [ParameterId.VisualId]: visualId,
      [ParameterId.SelectionUpdateType]: selectionType
    };

    switch (selectionCriteriaType) {
      case SelectionCriteriaType.HierarchicalType: {
        parameters[ParameterId.HierValSelectionModels] = selectionModelContainer.hierModelArr;
        break;
      }
      case SelectionCriteriaType.RangeType: {
        parameters[ParameterId.QuantRangeSelectionModels] = selectionModelContainer.quantModelArr;
        break;
      }
      case SelectionCriteriaType.DimensionType: {
        parameters[ParameterId.DimValSelectionModels] = selectionModelContainer.dimModelArr;
        break;
      }
      default:
        break;
    }
    return this.execute(VerbId.SelectByValue, parameters).then<void>(response => {
      // Expecting an empty model and hence the void response.
      return;
      // TODO Investigate the error response with multiple output params and throw error accordingly.
    });
  }

  /**
 * Method to select marks for the given worksheet.
 *
 * @param visualId
 * @param MarkInfo
 * @param selectionUpdateType
 */
  public selectMarksByIdAsync(visualId: VisualId,
    marks: Array<Contract.MarkInfo>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    if (marks.length === 0) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'Marks info missing for selecting marks by Id');
    }

    const selectionType: string = this.validateSelectionUpdateType(selectionUpdateType);
    let selectionModelContainer: SelectionModelsContainer = this.parseSelectionIds(marks);

    const parameters: ExecuteParameters = {
      [ParameterId.VisualId]: visualId,
      [ParameterId.SelectionUpdateType]: selectionType,
      [ParameterId.Selection]: selectionModelContainer.selection
    };
    return this.execute(VerbId.SelectByValue, parameters).then<void>(response => {
      // Expecting an empty model and hence the void response.
      return;
      // TODO Investigate the error response with multiple output params and throw error accordingly.
    });
  }

  /**
   * Method to prepare the pres models for selection by MarksInfo
   * @param marks
   */
  private parseSelectionIds(marks: Array<Contract.MarkInfo>): SelectionModelsContainer {
    let ids: Array<string> = [];
    let selectionModelContainer: SelectionModelsContainer = new SelectionModelsContainer();
    for (let i = 0; i < marks.length; i++) {
      let tupleId: Number | undefined = marks[i].tupleId;
      if (tupleId !== undefined && tupleId !== null) { // If tuple id is provided use that instead of pair
        ids.push(tupleId.toString()); // collect the tuple ids
      } else {
        throw new TableauError(ErrorCodes.InternalError, 'tupleId parsing error');
      }
    }
    if (ids.length !== 0) { // tuple ids based selection
      let tupleSelectionModel: TupleSelectionModel = new TupleSelectionModel();
      tupleSelectionModel.selectionType = 'tuples';
      tupleSelectionModel.objectIds = ids;
      selectionModelContainer.selection = tupleSelectionModel;
    }
    return selectionModelContainer;
  }
  /**
   * Method to prepare the pres models for selection by values.
   *
   * Supports 3 types for selection:
   * 1) hierarchical value based selection
   * 2) range value based selection
   * 3) Dimension value based selection
   *
   * @param marks
   * @param hierModelArr
   * @param dimModelArr
   * @param quantModelArr
   * @param selection
   */
  private parseSelectionMarks(selectionCriterias: Array<Contract.SelectionCriteria>,
    selectionType: SelectionCriteriaType): SelectionModelsContainer {
    let selectionModelContainer: SelectionModelsContainer = new SelectionModelsContainer();
    let mixedSelectionsError: boolean = false;

    for (let i = 0; i < selectionCriterias.length; i++) {
      const st = selectionCriterias[i];
      if (st.fieldName && (st.value !== undefined && st.value !== null)) {
        let catRegex = new RegExp('(\[[A-Za-z0-9]+]).*', 'g');
        let rangeOption: Contract.RangeValue = st.value as Contract.RangeValue;
        if (catRegex.test(st.fieldName)) { // Hierarchical value selection
          if (selectionType === SelectionCriteriaType.HierarchicalType) {
            let hierModel: HierarchicalSelectionModel =
              this.addToParamsList(st.fieldName, st.value as Contract.CategoricalValue) as HierarchicalSelectionModel;
            selectionModelContainer.hierModelArr.push(hierModel);
          } else {
            mixedSelectionsError = true;
            break;
          }
        } else if ((rangeOption as Contract.RangeValue).min !== undefined
          && (rangeOption as Contract.RangeValue).max !== undefined) { // Range value selection
          if (selectionType === SelectionCriteriaType.RangeType) {
            let quantModel: RangeSelectionModel = this.addToRangeParamsList(st.fieldName, rangeOption);
            selectionModelContainer.quantModelArr.push(quantModel);
          } else {
            mixedSelectionsError = true;
            break;
          }
        } else { // Dimension value selection
          if (selectionType === SelectionCriteriaType.DimensionType) {
            let dimModel: DimensionSelectionModel =
              this.addToParamsList(st.fieldName, st.value as Contract.CategoricalValue) as DimensionSelectionModel;
            selectionModelContainer.dimModelArr.push(dimModel);
          } else {
            mixedSelectionsError = true;
            break;
          }
        }
      }
    }

    if (mixedSelectionsError) {
      throw new TableauError(ErrorCodes.InternalError, 'Selection Criteria parsing error');
    }
    return selectionModelContainer;
  }

  /**
   *
   * @param selectionCriterias Validate and determine the selection criterias type.
   */
  private validateSelectionCriteria(selectionCriteria: Contract.SelectionCriteria): SelectionCriteriaType {
    let selectionType: SelectionCriteriaType;
    // Determine the type of selection, this command is by looking at the first selection
    let crit: Contract.SelectionCriteria = selectionCriteria;

    let catRegex = new RegExp('(\[[A-Za-z0-9]+]).*', 'g');
    let rangeOption: Contract.RangeValue = crit.value as Contract.RangeValue;

    if (crit.fieldName && (crit.value !== undefined && crit.value !== null)) {
      if (catRegex.test(crit.fieldName)) { // Hierarchical value selection
        selectionType = SelectionCriteriaType.HierarchicalType;
      } else if ((rangeOption as Contract.RangeValue).min !== undefined
        && (rangeOption as Contract.RangeValue).max !== undefined) { // Range value selection
        selectionType = SelectionCriteriaType.RangeType;
      } else { // Dimersion value selection
        selectionType = SelectionCriteriaType.DimensionType;
      }
    } else {
      throw new TableauError(ErrorCodes.InternalError, 'Selection Criteria parsing error');
    }
    return selectionType;
  }

  /**
   * Method to transform the key value pair into value based pres model object.
   *
   * @param valueSelectionModel
   * @param fieldName
   * @param value
   */
  private addToParamsList(fieldName: string, value: Contract.CategoricalValue): ValueSelectionModel {
    let valueSelectionModel: ValueSelectionModel = new ValueSelectionModel();
    let markValues: Array<string> = [];

    if (value instanceof Array) {
      let valueArr: Array<string> = value;
      for (let i = 0; i < valueArr.length; i++) {
        markValues.push(Param.serializeParameterValue(valueArr[i]));
      }
    } else {
      markValues.push(Param.serializeParameterValue(value));
    }

    valueSelectionModel.qualifiedFieldCaption = fieldName;
    valueSelectionModel.selectValues = markValues;
    return valueSelectionModel;
  }

  /**
   * Method to transform the key value pair into range based selection pres model.
   *
   * TODO: Need to handle the parsing of date type values.
   *
   * @param valueSelectionModel
   * @param fieldName
   * @param value
   */
  private addToRangeParamsList(fieldName: string, value: Contract.RangeValue): RangeSelectionModel {
    let rangeSelectionModel: RangeSelectionModel = new RangeSelectionModel();
    rangeSelectionModel.qualifiedFieldCaption = fieldName;
    if (value.max !== undefined && value.max !== null) {
      rangeSelectionModel.maxValue = Param.serializeParameterValue(value.max);
    }
    if (value.min !== undefined && value.min !== null) {
      rangeSelectionModel.minValue = Param.serializeParameterValue(value.min);
    }
    rangeSelectionModel.included = this.validateNullOptionType(value.nullOption);
    return rangeSelectionModel;
  }

  /**
   * Method to validate the selection update type.
   *
   * @param selectionUpdateType
   */
  private validateSelectionUpdateType(selectionUpdateType: SelectionUpdateType): string {
    if (selectionUpdateType === SelectionUpdateType.Replace) {
      return SelectionUpdateTypeInternal.Replace;
    } else if (selectionUpdateType === SelectionUpdateType.Add) {
      return SelectionUpdateTypeInternal.Add;
    } else if (selectionUpdateType === SelectionUpdateType.Remove) {
      return SelectionUpdateTypeInternal.Remove;
    }
    return SelectionUpdateTypeInternal.Replace;
  }

  /**
   * Method to validate the include type for range selection.
   *
   * @param nullOption
   */
  private validateNullOptionType(nullOption: FilterNullOption | undefined): string {
    if (nullOption) {
      if (nullOption === FilterNullOption.NullValues) {
        return QuantitativeIncludedValues.IncludeNull;
      } else if (nullOption === FilterNullOption.NonNullValues) {
        return QuantitativeIncludedValues.IncludeNonNull;
      } else if (nullOption === FilterNullOption.AllValues) {
        return QuantitativeIncludedValues.IncludeAll;
      }
    }

    return QuantitativeIncludedValues.IncludeAll;
  }

}

/**
 * Enum for the different selection criteria types.
 */
enum SelectionCriteriaType {
  HierarchicalType = 1,
  RangeType = 2,
  DimensionType = 3,
  TuplesType = 4,
}
