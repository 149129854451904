import { ErrorCodes } from '../../../ExternalContract/Namespaces/Tableau';
import { DialogOptions } from '../../../ExtensionsApiExternalContract';

import {
  ExecuteParameters,
  ExtensionDialogResult,
  ParameterId,
  VerbId
} from '@tableau/api-internal-contract-js';

import { ServiceImplBase, TableauError } from '../../../ApiShared';

import { ExtensionsServiceNames } from '../ExtensionsServiceNames';
import { UIService } from '../UIService';

const DEFAULT_DIALOG_HEIGHT: number = 400; // in pixels
const DEFAULT_DIALOG_WIDTH: number = 600; // in pixels

export class UIServiceImpl extends ServiceImplBase implements UIService {
  public get serviceName(): string {
    return ExtensionsServiceNames.UIService;
  }

  public displayDialogAsync(url: string, payload: string, options?: DialogOptions): Promise<void> {
    let parameters: ExecuteParameters = {
      [ParameterId.ExtensionDialogUrl]: url,
      [ParameterId.ExtensionDialogPayload]: payload
    };

    const h: number = ((options) && (options.height)) ? options.height : DEFAULT_DIALOG_HEIGHT;
    const w: number = ((options) && (options.width)) ? options.width : DEFAULT_DIALOG_WIDTH;

    // On the platform side, we do something reasonable regardess of whether the passed
    // height and width are too large or too small.  But this likely indicates a developer error,
    // so we throw an error here to help with debugging.
    if (h <= 0 || w <= 0) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'Size parameters for displayDialogAsync must be positive');
    }

    parameters[ParameterId.ExtensionDialogH] = h;
    parameters[ParameterId.ExtensionDialogW] = w;

    return this.execute(VerbId.DisplayDialog, parameters).then(response => {
      const dialogResult = response.result as ExtensionDialogResult;
      switch (dialogResult) {
        case ExtensionDialogResult.DialogAlreadyOpen:
          throw new TableauError(ErrorCodes.DialogAlreadyOpen, 'There already exists an open dialog for this extension.');
        case ExtensionDialogResult.InvalidDomain:
          throw new TableauError(ErrorCodes.InvalidDomainDialog,
            'The url of an extension dialog must match the domain of the parent extension.');
        default: // Success case
          return;
      }
    });
  }

  public closeDialog(payload?: string): Promise<void> {
    let parameters: ExecuteParameters = (payload) ? { [ParameterId.ExtensionDialogPayload]: payload } : {};

    return this.execute(VerbId.CloseDialog, parameters).then(response => {
      return;
    });
  }
}
