import { DashboardObjectType } from '../ExternalContract/Namespaces/Tableau';
import * as Contract from '../SharedApiExternalContract';

/**
 * Implementation of the dashboard objects - the zones in a dashboard.
 * This does not follow the Impl pattern as it is just a property bag.
 */
export class DashboardObject implements Contract.DashboardObject {
  public constructor(
    private _dashboard: Contract.Dashboard,
    private _type: DashboardObjectType,
    private _position: Contract.Point,
    private _size: Contract.Size,
    private _worksheet: Contract.Worksheet | undefined,
    private _name: string,
    private _isFloating: boolean,
    private _isVisible: boolean,
    private _id: number
  ) { }

  public get dashboard(): Contract.Dashboard {
    return this._dashboard;
  }

  public get type(): DashboardObjectType {
    return this._type;
  }

  public get position(): Contract.Point {
    return this._position;
  }

  public get size(): Contract.Size {
    return this._size;
  }

  public get worksheet(): Contract.Worksheet | undefined {
    return this._worksheet;
  }

  public get name(): string {
    return this._name;
  }

  public get isFloating(): boolean {
    return this._isFloating;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get id(): number {
    return this._id;
  }
}
