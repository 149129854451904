import { SelectionUpdateType, TableauEventType, FilterUpdateType, FilterNullOption } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';
import {
  DataSchema,
  DataSource as DataSourceInfo,
  FilterEvent, NotificationId,
  VisualId,
  WorksheetDataSourceInfo
} from '@tableau/api-internal-contract-js';

import { DataSource } from '../DataSource';
import { Worksheet } from '../Worksheet';

import { DataSourceImpl } from './DataSourceImpl';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { SingleEventManagerImpl } from './SingleEventManagerImpl';

import { FilterChangedEvent } from '../Events/FilterChangedEvent';
import { MarksSelectedEvent } from '../Events/MarksSelectedEvent';
import { SingleEventManager } from '../SingleEventManager';

import { DataSourceService } from '../Services/DataSourceService';
import { FilterService } from '../Services/FilterService';
import { GetDataService, GetDataType } from '../Services/GetDataService';
import { NotificationService } from '../Services/NotificationService';
import { SelectionService } from '../Services/SelectionService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { LogicalTable } from '../LogicalTable';

const visualIdsAreEqual = function (a: VisualId, b: VisualId): boolean {
  return a && b &&
    a.worksheet === b.worksheet &&
    a.dashboard === b.dashboard &&
    a.storyboard === b.storyboard &&
    a.storyPointID === b.storyPointID;
};

export class WorksheetImpl extends SheetImpl {
  public constructor(sheetInfoImpl: SheetInfoImpl,
    private _visualId: VisualId,
    private _parentDashboard: Contract.Dashboard) {
    super(sheetInfoImpl);
  }

  public get parentDashboard(): Contract.Dashboard {
    return this._parentDashboard;
  }

  /**
   * Helper method which goes through and registers each event type this impl knows about
   * with the NotificationService. It returns an array of SingleEventManager objects which
   * can then be passed to an EventListenerManager to handle user registration / unregistration.
   *
   * @param {Worksheet} worksheet The worksheet object which will be included with the event notifications
   * @returns {Array<SingleEventManager>} Collection of event managers to pass to an EventListenerManager
   */
  public initializeEvents(worksheet: Worksheet): Array<SingleEventManager> {
    const results = new Array<SingleEventManager>();
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.instance.getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      return results;
    }

    // Initialize all of the event managers we'll need (one for each event type)
    const marksEvent = new SingleEventManagerImpl<MarksSelectedEvent>(TableauEventType.MarkSelectionChanged);
    notificationService.registerHandler(NotificationId.SelectedMarksChanged, (model) => {
      const visualId = model as VisualId;
      return visualIdsAreEqual(visualId, this.visualId);
    }, (viz: VisualId) => {
      marksEvent.triggerEvent(() => new MarksSelectedEvent(worksheet));
    });

    const filterEvent = new SingleEventManagerImpl<FilterChangedEvent>(TableauEventType.FilterChanged);
    notificationService.registerHandler(NotificationId.FilterChanged, (model) => {
      const filterEventResponse = model as FilterEvent;
      return this.visualId.worksheet === filterEventResponse.visualId.worksheet;
    }, (event: FilterEvent) => {
      filterEvent.triggerEvent(() => new FilterChangedEvent(worksheet, event.fieldName));
    });

    results.push(marksEvent);
    results.push(filterEvent);

    // TODO - other event types

    return results;
  }

  public get visualId(): VisualId {
    return this._visualId;
  }

  public applyFilterAsync(
    fieldName: string, values: Array<string>, updateType: FilterUpdateType, options: Contract.FilterOptions): Promise<string> {
    ErrorHelpers.verifyEnumValue<FilterUpdateType>(updateType, FilterUpdateType, 'FilterUpdateType');

    const service = ApiServiceRegistry.instance.getService<FilterService>(ServiceNames.Filter);
    return service.applyFilterAsync(this.visualId, fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');
    ErrorHelpers.verifyParameter(filterOptions, 'filterOptions');

    if (filterOptions.nullOption) {
      ErrorHelpers.verifyEnumValue<FilterNullOption>(filterOptions.nullOption, FilterNullOption, 'FilterNullOption');
    } else {
      ErrorHelpers.verifyRangeParamType(filterOptions.min, filterOptions.max);
    }

    const service = ApiServiceRegistry.instance.getService<FilterService>(ServiceNames.Filter);
    return service.applyRangeFilterAsync(this.visualId, fieldName, filterOptions);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    ErrorHelpers.verifyParameter(fieldName, 'fieldName');

    const service = ApiServiceRegistry.instance.getService<FilterService>(ServiceNames.Filter);
    return service.clearFilterAsync(this.visualId, fieldName);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    const service = ApiServiceRegistry.instance.getService<DataSourceService>(ServiceNames.DataSourceService);

    return service.getDataSourcesAsync(this.visualId).then<Array<Contract.DataSource>>(result => {
      const dataSchema: DataSchema = result as DataSchema;
      const worksheetDataSourceInfo: WorksheetDataSourceInfo = dataSchema.worksheetDataSchemaMap[this.name];

      let dataSources: Array<Contract.DataSource> = [];

      // First, add the primary datasource.  By convention, it comes first in the returned array.
      let primaryId: string = worksheetDataSourceInfo.primaryDataSource;
      dataSources.push(this.createDataSourceFromInfo(dataSchema.dataSources[primaryId]));

      // Then, loop through any secondary data sources and add them.
      for (let secondaryId of worksheetDataSourceInfo.referencedDataSourceList) {
        if (secondaryId !== primaryId) {
          dataSources.push(this.createDataSourceFromInfo(dataSchema.dataSources[secondaryId]));
        }
      }

      return dataSources;
    });
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    const service = ApiServiceRegistry.instance.getService<FilterService>(ServiceNames.Filter);
    return service.getFiltersAsync(this.visualId);
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    const service = ApiServiceRegistry.instance.getService<GetDataService>(ServiceNames.GetData);
    return service.getSelectedMarksAsync(this.visualId);
  }

  public getHighlightedMarksAsync(): Promise<Contract.MarksCollection> {
    const service = ApiServiceRegistry.instance.getService<GetDataService>(ServiceNames.GetData);
    return service.getHighlightedMarksAsync(this.visualId);
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    const service = ApiServiceRegistry.instance.getService<GetDataService>(ServiceNames.GetData);
    options = options || {};

    return service.getUnderlyingDataAsync(
      this.visualId, GetDataType.Summary, !!options.ignoreAliases, !!options.ignoreSelection, true, 0);
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    const service = ApiServiceRegistry.instance.getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingDataAsync(
      this.visualId,
      GetDataType.Underlying,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.maxRows || 0);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    const service = ApiServiceRegistry.instance.getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.getUnderlyingTablesAsync(this.visualId).then<Array<Contract.LogicalTable>>(logicalTableInfos => {
      return logicalTableInfos.map(logicalTableInfo => new LogicalTable(logicalTableInfo));
    });
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options?: Contract.GetUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    const service = ApiServiceRegistry.instance.getService<GetDataService>(ServiceNames.GetData);
    options = options || {};
    return service.getUnderlyingTableDataAsync(
      this.visualId,
      logicalTableId,
      !!options.ignoreAliases,
      !!options.ignoreSelection,
      !!options.includeAllColumns,
      options.maxRows || 0);
  }

  public clearSelectedMarksAsync(): Promise<void> {
    const service = ApiServiceRegistry.instance.getService<SelectionService>(ServiceNames.Selection);
    return service.clearSelectedMarksAsync(this.visualId);
  }

  public selectMarksByValueAsync(selections: Array<Contract.SelectionCriteria>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    ErrorHelpers.verifyParameter(selections, 'fieldName');
    ErrorHelpers.verifyEnumValue<SelectionUpdateType>(selectionUpdateType, SelectionUpdateType, 'SelectionUpdateType');

    const service = ApiServiceRegistry.instance.getService<SelectionService>(ServiceNames.Selection);
    return service.selectMarksByValueAsync(this.visualId, selections, selectionUpdateType);
  }

  public selectMarksByIdAsync(selections: Array<Contract.MarkInfo>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    ErrorHelpers.verifyParameter(selections, 'fieldName');
    ErrorHelpers.verifyEnumValue<SelectionUpdateType>(selectionUpdateType, SelectionUpdateType, 'SelectionUpdateType');

    const service = ApiServiceRegistry.instance.getService<SelectionService>(ServiceNames.Selection);
    return service.selectMarksByIdAsync(this.visualId, selections, selectionUpdateType);
  }

  private createDataSourceFromInfo(dataSourceInfo: DataSourceInfo): Contract.DataSource {
    const dataSourceImpl = new DataSourceImpl(dataSourceInfo);
    const dataSource = new DataSource(dataSourceImpl);
    dataSourceImpl.initializeWithPublicInterfaces(dataSource);
    return dataSource;
  }
}
