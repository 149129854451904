import * as Contract from '../SharedApiExternalContract';

import { DashboardImpl } from './Impl/DashboardImpl';
import { Sheet } from './Sheet';

export class Dashboard extends Sheet implements Contract.Dashboard {
  public constructor(private _dashboardImpl: DashboardImpl) {
    super(_dashboardImpl);
    _dashboardImpl.initializeWithPublicInterfaces(this);
  }

  public get worksheets(): Array<Contract.Worksheet> {
    return this._dashboardImpl.worksheets;
  }

  public get objects(): Array<Contract.DashboardObject> {
    return this._dashboardImpl.objects;
  }

  public setZoneVisibilityAsync(zoneVisibilityMap: Contract.ZoneVisibilityMap): Promise<void> {
    return this._dashboardImpl.setZoneVisibilityAsync(zoneVisibilityMap);
  }
}
