import { ApiServiceRegistry } from './ServiceRegistry';
import { DataSourceServiceImpl } from './impl/DataSourceServiceImpl';
import { FilterServiceImpl } from './impl/FilterServiceImpl';
import { GetDataServiceImpl } from './impl/GetDataServiceImpl';
import { InternalApiDispatcher, VersionNumber } from '@tableau/api-internal-contract-js';
import { NotificationServiceImpl } from './impl/NotificationServiceImpl';
import { ParametersServiceImpl } from './impl/ParametersServiceImpl';
import { SelectionServiceImpl } from './impl/SelectionServiceImpl';
import { ZoneServiceImpl } from './impl/ZoneServiceImpl';

export function registerAllSharedServices(dispatcher: InternalApiDispatcher, platformVersion: VersionNumber): void {
  ApiServiceRegistry.instance.registerService(new DataSourceServiceImpl(dispatcher, platformVersion));
  ApiServiceRegistry.instance.registerService(new GetDataServiceImpl(dispatcher));
  ApiServiceRegistry.instance.registerService(new FilterServiceImpl(dispatcher));
  ApiServiceRegistry.instance.registerService(new NotificationServiceImpl(dispatcher));
  ApiServiceRegistry.instance.registerService(new ParametersServiceImpl(dispatcher));
  ApiServiceRegistry.instance.registerService(new SelectionServiceImpl(dispatcher));
  ApiServiceRegistry.instance.registerService(new ZoneServiceImpl(dispatcher));
}
