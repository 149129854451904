import {
  CreateExternalCompatibleVersionConverter,
  INTERNAL_CONTRACT_VERSION,
  ExecuteParameters,
  ExecuteResponse,
  ExternalContractVersionConverter,
  InternalApiDispatcher,
  Notification,
  NotificationHandler,
  VerbId,
  VersionLessThan,
  VersionNumber
} from '@tableau/api-internal-contract-js';

/**
 * Implementation of the InternalApiDispatcher which supports upgrading and downgrading the input
 * internal contract to the version that this module is built against
 *
 * @class VersionedExternalApiDispatcher
 * @implements {InternalApiDispatcher}
 */
export class VersionedExternalApiDispatcher implements InternalApiDispatcher {
  private _versionConverter: ExternalContractVersionConverter;
  private _notificationHandlers: Array<NotificationHandler>;

  /**
   * Creates a new instance of the VersionedExternalApiDispatcher
   * We have multiple version converting dispatchers that work together.
   * If needed, the VersionedExternalApiDispatcher wraps either the InternalApiDispatcher (desktop)
   * or the CrossFrameDispatcher (server).
   * The Internal/CrossFrame dispatchers handle an updated platform with an older external library.
   * (The CrossFrameDispatcher sends messages across the frame, and it is handled by the PresLayerHandler.)
   * Meanwhile, the VersionedExternalApiDispatcher handles an updated external library with an older platform.

   * @param _apiDelegateDispatcher The delegate that does the actual work.
   * @param platformVersionNumber The version of the internal contract which the platform module is using.
   * This number will be used to figure out how to downgrade incoming commands and upgrade the results
   */
  public constructor(
    private _apiDelegateDispatcher: InternalApiDispatcher,
    platformVersionNumber: VersionNumber) {

    this._versionConverter = CreateExternalCompatibleVersionConverter(INTERNAL_CONTRACT_VERSION, platformVersionNumber);

    this._notificationHandlers = [];
    _apiDelegateDispatcher.registerNotificationHandler((notification: Notification): void => {
      if (this._notificationHandlers.length === 0) {
        return;
      }
      const upgradedNotification = this._versionConverter.upgradeNotification(notification);
      this._notificationHandlers.forEach(handler => {
        handler(upgradedNotification);
      });
    });
  }

  public static needsVersionConverter(platformVersion: VersionNumber): boolean {
    // If our platform is less than external library version, then we need a converter
    return VersionLessThan(platformVersion, INTERNAL_CONTRACT_VERSION);
  }

  public execute(verb: VerbId, parameters: ExecuteParameters): Promise<ExecuteResponse> {
    try {
      const downgradeParameters = this._versionConverter.downgradeExecuteCall(verb, parameters);
      return this._apiDelegateDispatcher.execute(downgradeParameters.verb, downgradeParameters.parameters).then(response => {
        const upgradeResponse = this._versionConverter.upgradeExecuteReturn(response);
        return upgradeResponse;
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public registerNotificationHandler(handler: NotificationHandler): void {
    this._notificationHandlers.push(handler);
  }

  public unregisterNotificationHandler(handler: NotificationHandler): void {
    this._notificationHandlers = this._notificationHandlers.filter(h => h !== handler);
  }
}
