import { TableauEventType, ErrorCodes } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';

import { ParametersService } from '../Services/ParametersService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';

import { TableauError } from '../TableauError';
import { TableauSheetEvent } from './TableauSheetEvent';

export class ParameterChangedEvent extends TableauSheetEvent implements Contract.ParameterChangedEvent {
  public constructor(private _globalFieldName: string, sheet: Contract.Sheet) {
    super(TableauEventType.ParameterChanged, sheet);
  }

  public getParameterAsync(): Promise<Contract.Parameter> {
    // Call down to our service to get the parameter back via its field name
    const service = ApiServiceRegistry.instance.getService<ParametersService>(ServiceNames.Parameters);
    return service.findParameterByGlobalFieldNameAsync(this._globalFieldName, this.sheet).then(parameter => {
      if (parameter === undefined) {
        throw new TableauError(ErrorCodes.MissingParameter, `Cannot find parameter: ${this._globalFieldName}`);
      }

      return parameter;
    });
  }
}
