import { ExternalContractVersionConverter } from './ExternalContractVersionConverter';
import { ExecuteParameters, ExecuteResponse, Notification, VerbId } from '../../JsApiInternalContract';

// tslint:disable:no-any

/**
 * This version converter doesn't actually do anything but is useful for testing or when we have
 * a matching platform and external version number
*/
export class ExternalIdentityVersionConverter implements ExternalContractVersionConverter {
  public downgradeExecuteCall(verb: any, parameters: any): { verb: VerbId; parameters: ExecuteParameters; } {
    return {
      verb: verb as VerbId,
      parameters: parameters as ExecuteParameters
    };
  }

  public upgradeExecuteReturn(executeResponse: ExecuteResponse): ExecuteResponse {
    return executeResponse;
  }

  public upgradeNotification(notification: Notification): Notification {
    return notification;
  }
}
