import { InternalContractVersionConverter } from './InternalContractVersionConverter';
import * as Translations from './VersionTranslations';
import { StackingVersionConverter } from './StackingVersionConverter';
import { IdentityVersionConverter } from './IdentityVersionConverter';
import { DowngradeV2WorksheetNames, DowngradeFlipboardZoneID } from './VersionTranslations';
import { VersionNumber } from '../JsApiInternalContract';

/**
 * @returns true if lhs < rhs (ignoring fix number)
 * @param lhs
 * @param rhs
 */
export function VersionLessThan(lhs: VersionNumber, rhs: VersionNumber): boolean {
  if (lhs.major > rhs.major) {
    return false;
  }
  if (lhs.major < rhs.major) {
    return true;
  }
  return (lhs.minor < rhs.minor);
}

/**
 * @returns true if lhs == rhs (ignoring fix number)
 * @param lhs
 * @param rhs
 */
export function VersionEqualTo(lhs: VersionNumber, rhs: VersionNumber): boolean {
  return (lhs.major === rhs.major) && (lhs.minor === rhs.minor);
}

/**
 * @deprecated This function is deprecated, and will not be called from api-platform in 2019.2+.
 *
 * Creates a new InternalContractVersionConverter which has the ability to upgrade and downgrade the contract between the two versions
 * which are specified. If externalMajorVersion is greater than platformMajorVersion, an error will be thrown because
 * we won't know how to do those conversions.
 *
 * @see CreateCompatibleVersionConverter
 *
 * @param externalMajorVersion The version of the internal api which the external module is using
 * @param platformMajorVersion The version of the internal api which the platform is using
 */
export function CreateVersionConverter(externalMajorVersion: number, platformMajorVersion: number): InternalContractVersionConverter {

  // A mapping from the source version of a model -> the next version of the model. Each major
  // version bump can have an array of conversions to perform in order
  const executeUpgrades: { [version: number]: Array<Translations.UpgradeExecuteCall> } = {
    0: []
  };

  const executeDowngrades: { [version: number]: Array<Translations.DowngradeExecuteReturn> } = {
    0: [],
    1: [DowngradeV2WorksheetNames]
  };

  const notificationDowngrades: { [version: number]: Array<Translations.DowngradeNotification> } = {
    0: []
  };

  if (!Number.isInteger(externalMajorVersion) ||
    !Number.isInteger(platformMajorVersion) ||
    externalMajorVersion < 0 ||
    platformMajorVersion < 0) {

    throw new Error(`Versions must be positive integers:
    externalMajorVersion=${externalMajorVersion} platformMajorVersion=${platformMajorVersion}`);
  }

  if (externalMajorVersion > platformMajorVersion) {
    throw new Error(`External version must be less than or equal to platform version.
    externalMajorVersion=${externalMajorVersion} platformMajorVersion=${platformMajorVersion}`);
  }

  if (externalMajorVersion === platformMajorVersion) {
    // If we are using the exact same versions, just use the identity converter
    return new IdentityVersionConverter();
  }

  // Walk the span between the versions we have here and collect the upgrade and downgrades necessary
  let neededExecuteUpgrades: Array<Translations.UpgradeExecuteCall> = [];
  for (let i = externalMajorVersion; i < platformMajorVersion; i++) {
    if (i in executeUpgrades) {
      neededExecuteUpgrades.push(...executeUpgrades[i]);
    }
  }

  // Walk the span between them backwards to get the necessary downgrades
  let neededExecuteDowngrades: Array<Translations.DowngradeExecuteReturn> = [];
  let neededNotificationDowngrades: Array<Translations.DowngradeNotification> = [];
  for (let i = platformMajorVersion - 1; i >= externalMajorVersion; i--) {
    if (i in executeDowngrades) {
      neededExecuteDowngrades.push(...executeDowngrades[i]);
    }

    if (i in notificationDowngrades) {
      neededNotificationDowngrades.push(...notificationDowngrades[i]);
    }
  }

  return new StackingVersionConverter(
    externalMajorVersion, platformMajorVersion, neededExecuteUpgrades, neededExecuteDowngrades, neededNotificationDowngrades);
}

export interface MajorMinorTranslators<T> { [major: number]: { [minor: number]: Array<T> }; }

// A mapping from an older client version of internal-contract to the current platform version of this contract.
// Each version bump can have an array of translations to perform in order. Notice that this is
// different than the major upgrades/downgrades above because it handles both major and minor version changes.
// Also please note: downgradeExecuteCall is handled on the client/external side rather than platform side.
// When updating the major or minor version of our internal-contract, you will need to update these data structures.
// * If there are translations to add, add them to the version to "upgrade from" or "downgrade to".
export const ExecuteMinorUpgrades: MajorMinorTranslators<Translations.UpgradeExecuteCall> = {
  1: {
    9: [],                    // Note that we put upgrades from 1.9 to 1.10 in the [1][9] bucket
  }
};

export const ExecuteMinorDowngrades: MajorMinorTranslators<Translations.DowngradeExecuteReturn> = {
  1: {
    9: [],
  }
};

export const NotificationMinorDowngrades: MajorMinorTranslators<Translations.DowngradeNotification> = {
  1: {
    9: [],
    10: [DowngradeFlipboardZoneID]
  }
};

/**
 * Creates a new InternalContractVersionConverter which has the ability to upgrade and downgrade the contract between the two versions
 * which are specified. If externalMajorVersion is greater than platformMajorVersion, an error will be thrown because
 * we won't know how to do those conversions. As compared to CreateVersionConverter, this converter can also handle
 * minor updates, with upgrade/downgrade for both major and minor updates.
 *
 * @param externalVersion VersionNumber of the internal api which the external module is using
 * @param platformVersion VersionNumber of the internal api which the platform is using
 */
export function CreateCompatibleVersionConverter(
  externalVersion: VersionNumber,
  platformVersion: VersionNumber): InternalContractVersionConverter {

  return CreateCompatibleVersionConverterWithTranslators(
    externalVersion,
    platformVersion,
    ExecuteMinorUpgrades,
    ExecuteMinorDowngrades,
    NotificationMinorDowngrades);
}

/**
 * Implementation of CreateCompatibleVersionConverter. This function takes the upgrade, downgrade, and
 * notification arrays so that all the logic can be tested.
 *
 * @param externalVersion VersionNumber of the internal api which the external module is using
 * @param platformVersion VersionNumber of the internal api which the platform is using
 * @param upgrades MajorMinorTranslators for upgrades
 * @param downgrades MajorMinorTranslators for downgrades
 * @param notificationDowngrades MajorMinorTranslators for notification downgrades
 */
export function CreateCompatibleVersionConverterWithTranslators(
  externalVersion: VersionNumber,
  platformVersion: VersionNumber,
  upgrades: MajorMinorTranslators<Translations.UpgradeExecuteCall>,
  downgrades: MajorMinorTranslators<Translations.DowngradeExecuteReturn>,
  notificationDowngrades: MajorMinorTranslators<Translations.DowngradeNotification>): InternalContractVersionConverter {

  const externalMajorVersion: number = externalVersion.major;
  const externalMinorVersion: number = externalVersion.minor;
  const platformMajorVersion: number = platformVersion.major;

  if (externalMajorVersion > platformMajorVersion) {
    throw new Error(`External version must be less than or equal to platform version.
    externalMajorVersion=${externalMajorVersion} platformMajorVersion=${platformMajorVersion}`);
  }

  // If we are using the exact same versions (major.minor), just use the identity converter
  if (VersionEqualTo(externalVersion, platformVersion)) {
    return new IdentityVersionConverter();
  }

  // Walk the span between the versions we have here and collect the upgrade and downgrades necessary
  let neededExecuteUpgrades: Array<Translations.UpgradeExecuteCall> =
    GetNeededTranslations(externalMajorVersion, platformMajorVersion, externalMinorVersion, upgrades);

  let neededExecuteDowngrades: Array<Translations.DowngradeExecuteReturn> =
    GetNeededTranslations(externalMajorVersion, platformMajorVersion, externalMinorVersion, downgrades);

  let neededNotificationDowngrades: Array<Translations.DowngradeNotification> =
    GetNeededTranslations(externalMajorVersion, platformMajorVersion, externalMinorVersion, notificationDowngrades);

  // We want to apply the downgrades in reverse order in case of dependencies between them
  neededExecuteDowngrades.reverse();
  neededNotificationDowngrades.reverse();

  return StackingVersionConverter.fromData(
    externalVersion, platformVersion, neededExecuteUpgrades, neededExecuteDowngrades, neededNotificationDowngrades);
}

function GetNeededTranslations<T>(
  externalMajorVersion: number,
  platformMajorVersion: number,
  externalMinorVersion: number,
  majorMinorTranslators: MajorMinorTranslators<T>): Array<T> {

  let neededTranslations: Array<T> = [];

  for (let major = externalMajorVersion; major <= platformMajorVersion; major++) {
    if (major in majorMinorTranslators) {
      let start = (major === externalMajorVersion) ? externalMinorVersion : 0;
      let maximumMinorVersion = GetMaximumMinorIndex(Object.keys(majorMinorTranslators[major]));
      for (let minor = start; minor <= maximumMinorVersion; minor++) {
        if (minor in majorMinorTranslators[major]) {
          neededTranslations.push(...majorMinorTranslators[major][minor]);
        }
      }
    }
  }

  return neededTranslations;
}

export function GetMaximumMinorIndex(minorVersions: Array<string>): number {
  return minorVersions.map((a) => Number(a)).reduce((a, b) => a > b ? a : b);
}
