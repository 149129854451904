import { SheetType } from '../../ExternalContract/Namespaces/Tableau';
import { Size } from '../../SharedApiExternalContract';
import { SheetPath } from '@tableau/api-internal-contract-js';

export class SheetInfoImpl {
  public constructor(
    private _name: string,
    private _sheetType: SheetType,
    private _sheetSize: Size
  ) { }

  public get name(): string {
    return this._name;
  }

  public get sheetSize(): Size {
    return this._sheetSize;
  }

  public get sheetType(): SheetType {
    return this._sheetType;
  }

  public get sheetPath(): SheetPath {
    return {
      sheetName: this.name,
      isDashboard: this.sheetType === SheetType.Dashboard
      // TODO - Stories
    };
  }
}
