import { FieldAggregationType, FieldRoleType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';
import * as InternalContract from '@tableau/api-internal-contract-js';

import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';

export class FieldImpl {
  public constructor(private _fieldInfo: InternalContract.Field,
    private _parentDataSource: Contract.DataSource) { }

  public get name(): string {
    return this._fieldInfo.name;
  }

  public get id(): string {
    return this._fieldInfo.id;
  }

  public get description(): string | undefined {
    return this._fieldInfo.description;
  }

  public get aggregation(): FieldAggregationType {
    return InternalToExternalEnumMappings.fieldAggregationType.convert(this._fieldInfo.aggregation);
  }

  public get dataSource(): Contract.DataSource {
    return this._parentDataSource;
  }

  public get role(): FieldRoleType {
    return InternalToExternalEnumMappings.fieldRoleType.convert(this._fieldInfo.role);
  }

  public get isHidden(): boolean {
    return this._fieldInfo.isHidden;
  }

  public get isGenerated(): boolean {
    return this._fieldInfo.isGenerated;
  }

  public get isCalculatedField(): boolean {
    return this._fieldInfo.isCalculatedField;
  }

  public get isCombinedField(): boolean {
    return this._fieldInfo.isCombinedField;
  }
}
