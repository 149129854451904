import { SheetType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';
import { SheetPath } from '@tableau/api-internal-contract-js';

import { SheetInfoImpl } from './SheetInfoImpl';

import { ParametersService } from '../Services/ParametersService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';

export class SheetImpl {
  public constructor(private _sheetInfoImpl: SheetInfoImpl) {
  }

  public get name(): string {
    return this._sheetInfoImpl.name;
  }

  public get sheetType(): SheetType {
    return this._sheetInfoImpl.sheetType;
  }

  public get sheetPath(): SheetPath {
    return this._sheetInfoImpl.sheetPath;
  }

  public get size(): Contract.Size {
    return this._sheetInfoImpl.sheetSize;
  }

  public findParameterAsync(parameterName: string, sheet: Contract.Sheet): Promise<Contract.Parameter | undefined> {
    ErrorHelpers.verifyParameter(parameterName, 'parameterName');
    ErrorHelpers.verifyParameter(sheet, 'sheet');

    const service = ApiServiceRegistry.instance.getService<ParametersService>(ServiceNames.Parameters);
    return service.findParameterByNameAsync(parameterName, sheet);
  }

  public getParametersAsync(sheet: Contract.Sheet): Promise<Array<Contract.Parameter>> {
    ErrorHelpers.verifyParameter(sheet, 'sheet');

    const service = ApiServiceRegistry.instance.getService<ParametersService>(ServiceNames.Parameters);
    return service.getParametersForSheetAsync(this.sheetPath, sheet);
  }
}
