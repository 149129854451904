import {
  FilterUpdateType,
  FilterDomainType,
  FilterType as ExternalFilterType,
  ErrorCodes
} from '../../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../../SharedApiExternalContract';
import * as InternalContract from '@tableau/api-internal-contract-js';
import { TableauError } from '../../../ApiShared';

import {
  ExecuteParameters,
  FilterType,
  ParameterId,
  VerbId,
  VisualId
} from '@tableau/api-internal-contract-js';

import { ExternalToInternalEnumMappings as ExternalEnumConverter } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { InternalToExternalEnumMappings as InternalEnumConverter } from '../../EnumMappings/InternalToExternalEnumMappings';

import {
  CategoricalDomain,
  CategoricalFilter,
  RangeDomain,
  RangeFilter,
  RelativeDateFilter
} from '../../Models/FilterModels';

import { ServiceImplBase } from './ServiceImplBase';

import { FilterService } from '../FilterService';
import { ServiceNames } from '../ServiceRegistry';

import { DataValue } from '../../Models/GetDataModels';
import { Param } from '../../Utils/Param';
import { DataValueFactory } from '../../Utils/DataValueFactory';

export class FilterServiceImpl extends ServiceImplBase implements FilterService {
  public get serviceName(): string {
    return ServiceNames.Filter;
  }

  public applyFilterAsync(
    visualId: VisualId,
    fieldName: string,
    values: Array<string>,
    updateType: FilterUpdateType,
    filterOptions: Contract.FilterOptions): Promise<string> {
    const verb = VerbId.ApplyCategoricalFilter;
    const parameters: ExecuteParameters = {};
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.FieldName] = fieldName;
    if (!Array.isArray(values)) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'values parameter for applyFilterAsync must be an array');
    }
    parameters[ParameterId.FilterValues] = values;
    parameters[ParameterId.FilterUpdateType] = ExternalEnumConverter.filterUpdateType.convert(updateType);
    parameters[ParameterId.IsExcludeMode] =
      (filterOptions === undefined || filterOptions.isExcludeMode === undefined) ? false : filterOptions.isExcludeMode;

    return this.execute(verb, parameters).then<string>(response => {
      return fieldName;
    });
  }

  public applyRangeFilterAsync(visualId: VisualId, fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    const verb = VerbId.ApplyRangeFilter;
    const parameters: ExecuteParameters = {};


    if (filterOptions.min !== undefined && filterOptions.min !== null) {
      let min: string | number;
      if (filterOptions.min instanceof Date) {
        min = Param.serializeDateForPlatform(filterOptions.min);
      } else {
        min = filterOptions.min;
      }
      parameters[ParameterId.FilterRangeMin] = min;
    }

    if (filterOptions.max !== undefined && filterOptions.max !== null) {
      let max: string | number;
      if (filterOptions.max instanceof Date) {
        max = Param.serializeDateForPlatform(filterOptions.max);
      } else {
        max = filterOptions.max;
      }
      parameters[ParameterId.FilterRangeMax] = max;
    }

    // The null option is used with min+max for 'include-range' or 'include-range-or-null'
    if (filterOptions.nullOption) {
      parameters[ParameterId.FilterRangeNullOption] = ExternalEnumConverter.nullOptions.convert(filterOptions.nullOption);
    }

    parameters[ParameterId.FieldName] = fieldName;
    parameters[ParameterId.VisualId] = visualId;

    return this.execute(verb, parameters).then<string>(response => {
      return fieldName;
    });
  }

  public clearFilterAsync(visualId: VisualId, fieldName: string): Promise<string> {
    const verb = VerbId.ClearFilter;
    let parameters: ExecuteParameters = {};
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.FieldName] = fieldName;
    return this.execute(verb, parameters).then<string>(resposne => {
      return fieldName;
    });
  }

  public getFiltersAsync(visualId: VisualId): Promise<Array<Contract.Filter>> {
    const verb = VerbId.GetFilters;
    let parameters: ExecuteParameters = {};
    parameters[ParameterId.VisualId] = visualId;
    return this.execute(verb, parameters).then<Array<Contract.Filter>>(response => {
      let filters = response.result as Array<InternalContract.Filter>;
      return this.convertDomainFilters(filters);
    });
  }

  public getCategoricalDomainAsync(
    worksheetName: string,
    fieldId: string,
    domainType: FilterDomainType): Promise<Contract.CategoricalDomain> {
    const verb = VerbId.GetCategoricalDomain;
    let parameters: ExecuteParameters = {};
    parameters[ParameterId.VisualId] = {
      worksheet: worksheetName
    };

    parameters[ParameterId.FieldId] = fieldId;
    parameters[ParameterId.DomainType] = ExternalEnumConverter.filterDomainType.convert(domainType);
    return this.execute(verb, parameters).then<Contract.CategoricalDomain>(response => {
      let domain = response.result as InternalContract.CategoricalDomain;
      return this.convertCategoricalDomain(domain, domainType);
    });
  }

  public getRangeDomainAsync(worksheetName: string, fieldId: string, domainType: FilterDomainType): Promise<Contract.RangeDomain> {
    const verb = VerbId.GetRangeDomain;
    let parameters: ExecuteParameters = {};
    parameters[ParameterId.VisualId] = {
      worksheet: worksheetName
    };

    parameters[ParameterId.FieldId] = fieldId;
    parameters[ParameterId.DomainType] = ExternalEnumConverter.filterDomainType.convert(domainType);
    return this.execute(verb, parameters).then<Contract.RangeDomain>(response => {
      let domain = response.result as InternalContract.RangeDomain;

      return this.convertRangeDomain(domain, domainType);
    });
  }

  // Helper Methods
  private convertDomainFilters(domainFilters: Array<InternalContract.Filter>): Array<Contract.Filter> {
    let filters: Array<Contract.Filter> = [];
    domainFilters.forEach(domainFilter => {
      switch (domainFilter.filterType) {
        case FilterType.Categorical: {
          let filter = domainFilter as InternalContract.CategoricalFilter;
          if (filter) {
            filters.push(this.convertCategoricalFilter(filter));
          } else {
            throw new Error('Invalid Categorical Filter');
          }
          break;
        }

        case FilterType.Range: {
          let filter = domainFilter as InternalContract.RangeFilter;
          if (filter) {
            filters.push(this.convertRangeFilter(filter));
          } else {
            throw new Error('Invalid Range Filter');
          }
          break;
        }

        case FilterType.RelativeDate: {
          let filter = domainFilter as InternalContract.RelativeDateFilter;
          if (filter) {
            filters.push(this.convertRelativeDateFilter(filter));
          } else {
            throw new Error('Invalid Relative Date Filter');
          }
          break;
        }

        default: {
          break;
        }
      }
    });
    return filters;
  }

  private convertCategoricalFilter(domainFilter: InternalContract.CategoricalFilter): Contract.CategoricalFilter {
    let appliedValues: Array<Contract.DataValue> = domainFilter.values.map(dv => {
      return DataValueFactory.MakeFilterDataValue(dv);
    });

    return new CategoricalFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      FilterType.Categorical,
      appliedValues,
      domainFilter.isExclude,
      domainFilter.isAllSelected);
  }

  private convertRangeFilter(domainFilter: InternalContract.RangeFilter): Contract.RangeFilter {
    let minValue: DataValue = DataValueFactory.MakeFilterDataValue(domainFilter.min);
    let maxValue: DataValue = DataValueFactory.MakeFilterDataValue(domainFilter.max);
    return new RangeFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      FilterType.Range,
      minValue,
      maxValue,
      domainFilter.includeNullValues
    );
  }

  private convertRelativeDateFilter(domainFilter: InternalContract.RelativeDateFilter): Contract.RelativeDateFilter {
    let anchorDateValue: DataValue = DataValueFactory.MakeFilterDataValue(domainFilter.anchorDate);
    return new RelativeDateFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      ExternalFilterType.RelativeDate,
      anchorDateValue,
      InternalEnumConverter.dateStepPeriod.convert(domainFilter.periodType),
      InternalEnumConverter.dateRangeType.convert(domainFilter.rangeType),
      domainFilter.rangeN
    );
  }

  private convertCategoricalDomain(
    domain: InternalContract.CategoricalDomain,
    domainType: FilterDomainType): Contract.CategoricalDomain {
    let values: Array<DataValue> = domain.values.map((domainDv) => {
      return DataValueFactory.MakeFilterDataValue(domainDv);
    });
    return new CategoricalDomain(values, domainType);
  }

  private convertRangeDomain(domain: InternalContract.RangeDomain, domainType: FilterDomainType): Contract.RangeDomain {
    let min: DataValue = DataValueFactory.MakeFilterDataValue(domain.min);
    let max: DataValue = DataValueFactory.MakeFilterDataValue(domain.max);
    return new RangeDomain(
      min,
      max,
      domainType
    );
  }
}
