import { SheetType } from '../ExternalContract/Namespaces/Tableau';
import * as Contract from '../SharedApiExternalContract';

import { EventListenerManager } from './EventListenerManager';

import { SheetImpl } from './Impl/SheetImpl';

export class Sheet extends EventListenerManager implements Contract.Sheet {
  public constructor(private _sheetImpl: SheetImpl) {
    super();
  }

  public get name(): string {
    return this._sheetImpl.name;
  }

  public get sheetType(): SheetType {
    return this._sheetImpl.sheetType;
  }

  public get size(): Contract.Size {
    return this._sheetImpl.size;
  }

  public findParameterAsync(parameterName: string): Promise<Contract.Parameter | undefined> {
    return this._sheetImpl.findParameterAsync(parameterName, this);
  }

  public getParametersAsync(): Promise<Array<Contract.Parameter>> {
    return this._sheetImpl.getParametersAsync(this);
  }
}
