import { MajorMinorTranslators, GetMaximumMinorIndex, VersionEqualTo } from '../VersionConverterFactory';
import { ExternalContractVersionConverter } from './ExternalContractVersionConverter';
import * as TranslationsExternal from './ExternalVersionTranslations';
import { VersionNumber } from '../../JsApiInternalContract';
import { ExternalIdentityVersionConverter } from './ExternalIdentityVersionConverter';
import { ExternalStackingVersionConverter } from './ExternalStackingVersionConverter';
import { DowngradeUnderlyingTableDataAsync, DowngradeLogicalTableDataAsync, UpgradeDataTableTypes } from './ExternalVersionTranslations';


// A mapping from the current client version of internal-contract to an older platform version of the contract.
// Each version bump can have an array of translations to perform in order.
// These translations handle downgradeExecuteCall and upgradeExecuteReturn and are meant to be called on the
// client/external side.
// When updating the major or minor version of our internal-contract, you will need to update these data structures.
// * If there are translations to add, add them to the version to 'upgrade from' or 'downgrade to'.
export const ExecuteMinorDowngradeCallExternal: MajorMinorTranslators<TranslationsExternal.DowngradeExecuteCall> = {
  1: {
    9: [],                       // Note that we put downgrades from 1.10 to 1.9 in the [1][9] bucket
    13: [DowngradeUnderlyingTableDataAsync, DowngradeLogicalTableDataAsync],
  }
};

export const ExecuteMinorUpgradeReturnExternal: MajorMinorTranslators<TranslationsExternal.UpgradeExecuteReturn> = {
  1: {
    9: [UpgradeDataTableTypes]  // Note that we put upgrades from 1.9 to 1.10 in the [1][9] bucket
  }
};

export const ExecuteMinorUpgradeNotification: MajorMinorTranslators<TranslationsExternal.UpgradeNotification> = {
  1: {
    9: []                       // Note that we put upgrades from 1.9 to 1.10 in the [1][9] bucket
  }
};

/**
 * Creates a new ExternalContractVersionConverter which has the ability to upgrade and downgrade
 * the contract between the two versions which are specified. If externalMajorVersion is less than
 * platformMajorVersion, an ExternalIdentityVersionConverter will be returned.
 * Handles upgrade/downgrade for both major and minor updates.
 *
 * @param externalVersion VersionNumber of the internal api which the external module is using
 * @param platformVersion VersionNumber of the internal api which the platform is using
 */
export function CreateExternalCompatibleVersionConverter(
  externalVersion: VersionNumber,
  platformVersion: VersionNumber): ExternalContractVersionConverter {

  return CreateExternalCompatibleVersionConverterWithTranslators(
    externalVersion,
    platformVersion,
    ExecuteMinorDowngradeCallExternal,
    ExecuteMinorUpgradeReturnExternal,
    ExecuteMinorUpgradeNotification
  );
}

/**
 * Implementation of CreateExternalCompatibleVersionConverterWithTranslators.
 * This function takes the upgrade, downgrade arrays so that all the logic can be tested.
 *
 * @param externalVersion VersionNumber of the internal contract which the external module is using
 * @param platformVersion VersionNumber of the internal contract which the platform is using
 * @param upgrades MajorMinorTranslators for response upgrades
 * @param downgrades MajorMinorTranslators for execute call downgrades
 */
export function CreateExternalCompatibleVersionConverterWithTranslators(
  externalVersion: VersionNumber,
  platformVersion: VersionNumber,
  downgrades: MajorMinorTranslators<TranslationsExternal.DowngradeExecuteCall>,
  upgrades: MajorMinorTranslators<TranslationsExternal.UpgradeExecuteReturn>,
  upgradeNotifications: MajorMinorTranslators<TranslationsExternal.UpgradeNotification>)
  : ExternalContractVersionConverter {

  const externalMajorVersion: number = externalVersion.major;
  const platformMajorVersion: number = platformVersion.major;
  const platformMinorVersion: number = platformVersion.minor;

  // This check is present in VersionConverterFactory. We throw the same error here as well.
  // Hence we only need to check the minor versions for translations.
  if (externalMajorVersion > platformMajorVersion) {
    throw new Error(`External version must be less than or equal to platform version.
    externalMajorVersion=${externalMajorVersion} platformMajorVersion=${platformMajorVersion}`);
  }

  if (externalMajorVersion < platformMajorVersion || VersionEqualTo(externalVersion, platformVersion)) {
    return new ExternalIdentityVersionConverter();
  }

  // Walk the span between the versions we have here and collect the upgrade and downgrades necessary
  let neededExecuteCallDowngrade: Array<TranslationsExternal.DowngradeExecuteCall> =
    GetNeededExternalTranslations(platformMajorVersion, platformMinorVersion, downgrades);

  let neededExecuteReturnUpgrades: Array<TranslationsExternal.UpgradeExecuteReturn> =
    GetNeededExternalTranslations(platformMajorVersion, platformMinorVersion, upgrades);

  let neededNotificationUpgrades: Array<TranslationsExternal.UpgradeNotification> =
    GetNeededExternalTranslations(platformMajorVersion, platformMinorVersion, upgradeNotifications);

  // Reverse the downgrade calls, so that we start the downgrade from the external version to the platform version
  neededExecuteCallDowngrade.reverse();
  return new ExternalStackingVersionConverter(
    externalVersion, platformVersion, neededExecuteCallDowngrade, neededExecuteReturnUpgrades, neededNotificationUpgrades);
}

function GetNeededExternalTranslations<T>(
  platformMajorVersion: number,
  platformMinorVersion: number,
  majorMinorTranslators: MajorMinorTranslators<T>): Array<T> {

  let neededTranslations: Array<T> = [];

  if (platformMajorVersion in majorMinorTranslators) {
    let start = platformMinorVersion;
    let maximumMinorVersion = GetMaximumMinorIndex(Object.keys(majorMinorTranslators[platformMajorVersion]));
    for (let minor = start; minor <= maximumMinorVersion; minor++) {
      if (minor in majorMinorTranslators[platformMajorVersion]) {
        neededTranslations.push(...majorMinorTranslators[platformMajorVersion][minor]);
      }
    }
  }

  return neededTranslations;
}
