import { TableauEventType, ErrorCodes } from '../ExternalContract/Namespaces/Tableau';
import * as Contract from '../SharedApiExternalContract';

import { SingleEventManager } from './SingleEventManager';
import { TableauError } from './TableauError';

/**
 * Class designed to register and unregister handlers from a user. Only those events
 * which are added via AddNewEventType will be supported by this instance
 */
export class EventListenerManager implements Contract.EventListenerManager {
  private _eventListenerManagers: { [tableauEventType: string]: SingleEventManager; };

  public constructor() {
    this._eventListenerManagers = {};
  }

  public addEventListener(eventType: TableauEventType,
    handler: Contract.TableauEventHandlerFn): Contract.TableauEventUnregisterFn {
    if (!this._eventListenerManagers.hasOwnProperty(eventType)) {
      throw new TableauError(ErrorCodes.UnsupportedEventName, `Cannot add event, unsupported event type: ${eventType}`);
    }

    return this._eventListenerManagers[eventType].addEventListener(handler);
  }

  public removeEventListener(eventType: TableauEventType, handler: Contract.TableauEventHandlerFn): boolean {
    if (!this._eventListenerManagers.hasOwnProperty(eventType)) {
      throw new TableauError(ErrorCodes.UnsupportedEventName, `Cannot remove event, unsupported event type: ${eventType}`);
    }

    return this._eventListenerManagers[eventType].removeEventListener(handler);
  }

  protected addNewEventType(eventManager: SingleEventManager): void {
    this._eventListenerManagers[eventManager.eventType] = eventManager;
  }
}
