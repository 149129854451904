import { ZoneVisibilityType } from '../../../ExternalContract/Namespaces/Tableau';
import { DashboardObject } from '../../DashboardObject';
import { ErrorHelpers } from '../../Utils/ErrorHelpers';
import { Param } from '../../Utils/Param';
import { ExternalToInternalEnumMappings as ExternalEnumConverter } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ServiceImplBase } from './ServiceImplBase';
import { ServiceNames } from '../ServiceRegistry';
import { ZoneService } from '../ZoneService';
import * as Contract from '../../../SharedApiExternalContract';

export class ZoneServiceImpl extends ServiceImplBase implements ZoneService {
  public get serviceName(): string {
    return ServiceNames.Zone;
  }

  public setVisibilityAsync(
    dashboard: String,
    dashboardObjects: Array<DashboardObject>,
    zoneVisibilityMap: Contract.ZoneVisibilityMap): Promise<void> {

    const parameters = {
      [ParameterId.Dashboard]: dashboard,
      [ParameterId.ZoneIdsVisibilityMap]: {}
    };

    // zoneVisibilityMap can be either an object or a Map - convert it to a Map
    const parameterAsMap = Param.convertParameterToMap(zoneVisibilityMap, 'zoneVisibilityMap');

    parameterAsMap.forEach((value, key) => {
      ErrorHelpers.verifyZoneIsValid(dashboardObjects, Number.parseInt(key, 10));
      ErrorHelpers.verifyEnumValue<ZoneVisibilityType>(value, ZoneVisibilityType, 'ZoneVisibilityType');
      parameters[ParameterId.ZoneIdsVisibilityMap][key] = ExternalEnumConverter.setVisibilityType.convert(value);
    });

    return this.execute(VerbId.SetZoneVisibility, parameters).then<void>(response => {
      return;
    });
  }
}
