/**
 * This is your main. This is where you re-export everything you want to be publicly available.
 *
 * The build enforces that the file has the same name as the global variable that is exported.
 */

// The following polyfills are needed for IE11

import 'core-js/es/promise';
import 'core-js/es/map';
import 'core-js/es/array/find';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';
import 'core-js/es/object/set-prototype-of';
import 'core-js/es/number/is-nan';
import 'core-js/es/number/is-integer';
import 'core-js/es/number/parse-int';
import 'core-js/es/number/parse-float';

// Due to the way we configured webpack, we should be exporting things which will be under
// a global variable called "tableau". Export everything we want to be visible under tableau
// from this file.

import { ExtensionsImpl } from './ExtensionsApi/Impl/ExtensionsImpl';
import { Extensions } from './ExtensionsApi/Namespaces/Extensions';
import { ApiVersion } from './ApiShared';

declare var EXTENSION_VERSION_IS_ALPHA: boolean;
const isAlpha: boolean = typeof EXTENSION_VERSION_IS_ALPHA !== 'undefined' ? EXTENSION_VERSION_IS_ALPHA : false;

declare var EXTENSION_API_VERSION_NUMBER: string;
ApiVersion.SetVersionNumber(typeof EXTENSION_API_VERSION_NUMBER !== 'undefined' ? EXTENSION_API_VERSION_NUMBER : '0.0.0', isAlpha);

const extensionImpl = new ExtensionsImpl();
export const extensions = new Extensions(extensionImpl);

// Export Enums
// These show up under the tableau object. I.e. tableau.ExtensionContext.Server
export {
  ExtensionContext,
  ExtensionMode,
  AnalyticsObjectType,
  ColumnType,
  DashboardObjectType,
  DataType,
  DateRangeType,
  EncodingType,
  ErrorCodes,
  FieldAggregationType,
  FieldRoleType,
  FilterDomainType,
  FilterType,
  FilterUpdateType,
  FilterNullOption,
  MarkType,
  ParameterValueType,
  PeriodType,
  QuickTableCalcType,
  SelectionUpdateType,
  SheetType,
  SortDirection,
  TableauEventType,
  TrendLineModelType,
  ZoneVisibilityType
} from './ExternalContract/Namespaces/Tableau';
