import { TableauEventType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';

export class TableauEvent implements Contract.TableauEvent {
  private _type: TableauEventType;

  public constructor(type: TableauEventType) {
    this._type = type;
  }

  public get type(): TableauEventType {
    return this._type;
  }
}
