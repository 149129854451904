import { TableauEventType } from '../../ExternalContract/Namespaces/Tableau';
import * as Contract from '../../SharedApiExternalContract';

import { TableauWorksheetEvent } from './TableauWorksheetEvent';

export class MarksSelectedEvent extends TableauWorksheetEvent implements Contract.MarksSelectedEvent {
  public constructor(worksheet: Contract.Worksheet) {
    super(TableauEventType.MarkSelectionChanged, worksheet);
  }

  public getMarksAsync(): Promise<Contract.MarksCollection> {
    return this.worksheet.getSelectedMarksAsync();
  }
}
