import { ErrorCodes } from '../../../ExternalContract/Namespaces/Tableau';

import {
  ConnectionDescriptionSummary,
  DataSchema,
  ExecuteParameters,
  InternalApiDispatcher,
  LogicalTableInfo,
  ParameterId,
  TableInfo,
  TableInfos,
  VerbId,
  VisualId,
} from '@tableau/api-internal-contract-js';

import { ServiceImplBase } from './ServiceImplBase';

import { DataSourceService } from '../DataSourceService';
import { ServiceNames } from '../ServiceRegistry';

import { TableauError } from '../../TableauError';

import * as Contract from '../../../SharedApiExternalContract';
import * as InternalContract from '@tableau/api-internal-contract-js';

import { Field } from '../../Field';
import { FieldImpl } from '../../Impl/FieldImpl';

import { DataSource } from '../../DataSource';
import { DataSourceImpl } from '../../Impl/DataSourceImpl';

export const SENTINEL_LOGICAL_TABLE_INFO: LogicalTableInfo = {
  id: InternalContract.ApiShowDataTableSentinel.SingleTableId,
  caption: InternalContract.ApiShowDataTableSentinel.SingleTableCaption
};

export class DataSourceServiceImpl extends ServiceImplBase implements DataSourceService {
  public constructor(dispatcher: InternalApiDispatcher, private _platformVersion: InternalContract.VersionNumber) {
    super(dispatcher);
  }
  public get serviceName(): string {
    return ServiceNames.DataSourceService;
  }

  public refreshAsync(dataSourceId: string): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.DataSourceId]: dataSourceId,
      [ParameterId.DeltaTimeMs]: 0,
      [ParameterId.ShouldRefreshDS]: true
    };

    return this.execute(VerbId.RefreshDataSource, parameters).then<void>(response => {
      return;
    });
  }

  public getActiveTablesAsync(dataSourceId: string): Promise<Array<TableInfo>> {
    const joinParameters: ExecuteParameters = { [ParameterId.DataSourceId]: dataSourceId };

    // Get the description of the tables used by this connection
    return this.execute(VerbId.GetActiveTables, joinParameters).then<Array<TableInfo>>(joinResponse => {
      const tableInfos = joinResponse.result as TableInfos;

      // getActiveTables is unsupported for cubes and GA. We do not have a connection type property
      // available from the platform (intentionally, to reduce code churn as new connections are added).
      // Instead,just check if any tables are returned. This array will be empty for any non-table based datasource.
      if (tableInfos.tables.length === 0) {
        throw new TableauError(ErrorCodes.UnsupportedMethodForDataSourceType,
          `getActiveTables is not supported for: ${dataSourceId}`);
      }

      return tableInfos.tables;
    });
  }

  public getDataSourcesAsync(visualId: VisualId): Promise<DataSchema> {
    const parameters: ExecuteParameters = { [ParameterId.VisualId]: visualId };
    return this.execute(VerbId.GetDataSources, parameters).then<DataSchema>(response => {
      const dataSchema = response.result as DataSchema;
      return dataSchema;
    });
  }

  public getConnectionSummariesAsync(dataSourceId: string): Promise<ConnectionDescriptionSummary[]> {
    const params: ExecuteParameters = { [ParameterId.DataSourceId]: dataSourceId };

    // Get the description of the tables used by this connection
    return this.execute(VerbId.GetConnectionDescriptionSummaries, params).then<ConnectionDescriptionSummary[]>(response => {
      const descriptionSummaries = response.result as ConnectionDescriptionSummary[];
      return descriptionSummaries;
    });
  }

  public getFieldAsync(fieldId: string): Promise<Contract.Field> {
    const fieldIdComponents: Array<string> = this.parseFieldId(fieldId);
    const dataSourceId: string = fieldIdComponents[1];
    const fieldName: string = fieldIdComponents[2];

    const verb: VerbId = VerbId.GetDataSource;
    const parameters: ExecuteParameters = { [ParameterId.DataSourceId]: dataSourceId };

    return this.execute(verb, parameters).then<Contract.Field>(response => {
      const dataSource: InternalContract.DataSource = response.result as InternalContract.DataSource;
      const field: InternalContract.Field | undefined = dataSource.fields.find((f) => {
        return f.name === fieldName;
      });
      if (field === undefined) {
        throw new TableauError(ErrorCodes.InternalError, `Unable to find field with id '${fieldId}'`);
      }
      return this.convertField(field, this.convertDataSource(dataSource));
    });
  }

  public getLogicalTablesAsync(dataSourceId: string): Promise<Array<LogicalTableInfo>> {
    if (!this.isObjectModelSupportedByPlatform()) {
      /**
       * This sentinel ID can be passed to datasource.getLogicalTableData.
       * Once the desktop is upgraded to a version that supports object model,
       * the sentinel ID will automatically fetch the upgraded table.
       * */
      return new Promise<Array<LogicalTableInfo>>((resolve) =>
        resolve([SENTINEL_LOGICAL_TABLE_INFO])
      );
    }

    const params: ExecuteParameters = {
      [ParameterId.DataSourceId]: dataSourceId
    };
    return this.execute(VerbId.GetLogicalTables, params).then<Array<LogicalTableInfo>>(response => {
      return response.result as Array<LogicalTableInfo>;
    });
  }

  public getUnderlyingTablesAsync(visualId: VisualId): Promise<Array<LogicalTableInfo>> {
    if (!this.isObjectModelSupportedByPlatform()) {
      /**
       * This sentinel ID can be passed to worksheet.getUnderlyingTableData.
       * Once the desktop is upgraded to a version that supports object model,
       * the sentinel ID will automatically fetch the upgraded table.
       * */
      return new Promise<Array<LogicalTableInfo>>((resolve) =>
        resolve([SENTINEL_LOGICAL_TABLE_INFO])
      );
    }

    const params: ExecuteParameters = { [ParameterId.VisualId]: visualId };
    return this.execute(VerbId.GetUnderlyingTables, params).then<Array<LogicalTableInfo>>(response => {
      return response.result as Array<LogicalTableInfo>;
    });
  }

  private convertField(field: InternalContract.Field, dataSource: Contract.DataSource): Contract.Field {
    return new Field(new FieldImpl(field, dataSource));
  }

  private convertDataSource(dataSource: InternalContract.DataSource): Contract.DataSource {
    return new DataSource(new DataSourceImpl(dataSource));
  }

  private parseFieldId(fieldId: string): Array<string> {
    // we can expect exec to return a match to the entire field id at element 0, the datasource id at element 1
    // and the field name at element 2. Field id format is [dataSoucreId].[fieldName]
    return /^\[(.+)\]\.\[(.+)\]$/.exec(fieldId)!;
  }

  private isObjectModelSupportedByPlatform(): boolean {
    let platformVersionNoObjectModelSupport = { major: 1, minor: 13, fix: 0 };
    return InternalContract.VersionLessThan(platformVersionNoObjectModelSupport, this._platformVersion);
  }
}
